import React, { Fragment } from "react";
import {
  factionsComparator,
  casteComparator,
  groundStatEffectGroupComparator,
  globalSettings,
  stringComparator,
} from "../services/twwstats-services";
import { additionalIcons } from "../services/common";
// import MeleeAttackTooltip from "../components/meleeattack";
import MeleeDefenceTooltip from "../components/meleedefence";
import TooltipDesc from "../components/tooltipdesc";
import _ from "lodash";
import missileColdefs from "./missile-coldefs";

export default function createColumnDefs() {
  let colDefs = [
    // {
    //   type: ['UnitCardColumn'],
    //   headerName: 'Card',
    //   field: 'unit_card',
    //   pinned: 'left',
    // },
    {
      headerName: "Name",
      field: "name",
      maxWidth: 150,
      width: 150,
      minWidth: 150,
      pinned: "left",
    },
    {
      headerName: "Factions",
      field: "factions",
      synonyms: ["faction"],
      cellRenderer: "FactionsRenderer",
      valueFormatter: (params) =>
        _.reduce(params.value, (v, f) => (v += `${f.screen_name}, `), ""),
      maxWidth: 56,
      width: 56,
      minWidth: 56,
      getQuickFilterText: (params) => _.map(params.value, (f) => f.screen_name),
      comparator: factionsComparator,
    },
    {
      headerName: "Caste",
      field: "caste",
      synonyms: ["type"],
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      comparator: casteComparator,
    },
    {
      headerName: "Tier",
      field: "tier",
      synonyms: [],
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      comparator: stringComparator,
    },
    {
      groupId: "entity_size_group",
      openByDefault: localStorage.getItem("entity_size_group") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["booleanColumn"],
          headerName: "Is Large",
          field: "is_large",
          synonyms: ["large"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_entity_large.webp",
            falseIcon: "ui/skins/default/icon_entity_small.webp",
            headerTooltip: (props) => (
              <Fragment>
                <p>
                  Large units are affected by "Bonus_vs_Large" while small units
                  are affected by "Bonus_vs_Infantry"
                </p>
                <p>
                  Units size Medium or larger are considered "Large" for this
                  purpose
                </p>
                <p>
                  Units size Small or smaller are considered "Small" for this
                  purpose
                </p>
              </Fragment>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["entitySizeColumn"],
          cellRenderer: "EntitySizeRenderer",
          headerName: "Size",
          field: "entity_size",
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Mass",
          field: "mass",
          minWidth: 54,
          width: 54,
          maxWidth: 54,
          headerComponentParams: {
            // icon: "ui/skins/default/icon_cloud.webp"
            icon: "ui/campaign%20ui/effect_bundles/resource_marble.webp",
            headerTooltip: (props) => (
              <Fragment>
                <p>
                  The values of this column are my best guess at how mass is
                  computed. As a result, they could be wrong and/or innacurate
                  in many cases.
                </p>
              </Fragment>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["weightColumn"],
          headerName: "Weight",
          field: "weight",
          headerComponentParams: {
            fa: "balance-scale",
          },
        },
        {
          columnGroupShow: "open",
          headerName: "Ground Stat Effect Group",
          field: "ground_stat_effect_group",
          minWidth: 174,
          width: 174,
          maxWidth: 174,
          valueFormatter: (params) => params.value && params.value.group_name,
          getQuickFilterText: (params) =>
            params.value && params.value.group_name,
          cellRenderer: "GroundStatEffectGroupRenderer",
          headerComponentParams: {
            fa: "tree",
          },
          comparator: groundStatEffectGroupComparator,
        },
        // TODO #pack_assemblykit_discrepency
        // {
        //   columnGroupShow: 'open',
        //   type: ['integerColumn'],
        //   headerName: "Radius",
        //   field: "radius",
        //   headerComponentParams: {
        //     fa: 'street-view',
        //   }
        // },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Combat Reaction Radius",
          field: "combat_reaction_radius",
          headerComponentParams: {
            fa: "street-view",
            headerTooltip: (props) => (
              <p>
                How big the unit is during animations in regard to knockback
                effects and such. A higher number means the unit will collide
                with more entities and knock them around.
              </p>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Height",
          field: "height",
          headerComponentParams: {
            fa: "arrows-alt-v",
          },
        },
      ],
    },
    {
      type: ["integerColumn"],
      headerName: `Unit Size (Unit Size:${globalSettings().unit_size})`,
      field: "unit_size",
      headerComponentParams: {
        icon: "ui/skins/default/icon_mancount.webp",
      },
    },
    {
      groupId: "health_group",
      openByDefault: localStorage.getItem("health_group") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["integerColumn"],
          headerName: `Health (Unit Size:${globalSettings().unit_size})`,
          field: "health",
          synonyms: ["hit_points", "hp"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_health.webp",
          },
          maxWidth: 54,
          width: 54,
          minWidth: 54,
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: `Health per Entity (Unit Size:${
            globalSettings().unit_size
          })`,
          field: "health_per_entity",
          synonyms: [
            "hp_per_entity",
            "health_per_model",
            "hp_per_model",
            "hit_points_per_entity",
            "hit_points_per_model",
          ],
          headerComponentParams: {
            icon: "ui/skins/default/spacebar_unit_health_ammo.webp",
          },
          maxWidth: 54,
          width: 54,
          minWidth: 54,
        },
        {
          type: ["integerColumn"],
          headerName: `Barrier (Unit Size:${globalSettings().unit_size})`,
          field: "barrier_health",
          synonyms: ["barrier"],
          headerComponentParams: {
            icon: "ui/campaign ui/effect_bundles/barrier.webp",
          },
          maxWidth: 54,
          width: 54,
          minWidth: 54,
        },
      ],
    },
    {
      groupId: "armour_group",
      openByDefault: localStorage.getItem("armour_group") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          headerName: "Armour",
          field: "armour",
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_armour.webp",
            headerTooltip: (props) => (
              <Fragment>
                <p>
                  Reduces any non Armor-Piercing damage received. This includes
                  Magical and Flaming non Armor-Piercing damage.
                </p>
                <p>
                  Reduction amount is a random % between 0.5 * armour_value and
                  armour_value up to a maximum of 100%
                </p>
              </Fragment>
            ),
          },
          cellRenderer: "ArmourAndShieldRenderer",
          cellClass: (params) => {
            const compoundStatsClass =
              params.data["delta_" + params.colDef.field] === undefined
                ? null
                : params.data["delta_" + params.colDef.field] > 0
                ? "increased"
                : params.data["delta_" + params.colDef.field] < 0
                ? "reduced"
                : null;
            const relatedStatsClass =
              params.data["delta_parry_chance"] === undefined
                ? null
                : "adjusted";
            return "center " + (compoundStatsClass || relatedStatsClass || "");
          },
          maxWidth: 50,
          width: 50,
          minWidth: 50,
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Shield (parry chance from front missile attacks)",
          field: "parry_chance",
          synonyms: ["parry"],
          headerComponentParams: {
            icon: "ui/skins/default/modifier_icon_shield.webp",
          },
          maxWidth: 50,
          width: 50,
          minWidth: 50,
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Ward Save (Resist All)",
          field: "damage_mod_all",
          synonyms: ["resist_all", "ward_save"],
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/resistance_ward_save.webp",
            headerTooltip: (props) => (
              <Fragment>
                <p>Reduces any damage received</p>
                <p>
                  Resistances are applied independently of armor (dmg *
                  armor_reduction * resistance_reduction) and affect
                  Armor-Piercing Damage.
                </p>
                <p>
                  Against every attack, all aplicable resistances are added (up
                  to a maximum of 90, minimum of -100)
                </p>
              </Fragment>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Physical Resistance",
          field: "damage_mod_physical",
          synonyms: ["physical_resist", "physical_res"],
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/resistance_physical.webp",
            headerTooltip: (props) => (
              <Fragment>
                <p>Reduces any Non-Magical damage received</p>
                <p>
                  Note that Flaming attacks are not considered "Magical" on
                  their own and are subject to Physical Resistance
                </p>
                <p>
                  Resistances are applied independently of armor (dmg *
                  armor_reduction * resistance_reduction)
                </p>
                <p>
                  Both Base Damage and Armor-Piercing Damage are affected by
                  resistances
                </p>
                <p>
                  Against every attack, all aplicable resistances are added (up
                  to a maximum of 90, minimum of -100)
                </p>
              </Fragment>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Missile Resistance",
          field: "damage_mod_missile",
          synonyms: ["missile_resist", "missile_res"],
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/resistance_missile.webp",
            headerTooltip: (props) => (
              <Fragment>
                <p>Reduces any Missile Damage received</p>
                <p>
                  Resistances are applied independently of armor (dmg *
                  armor_reduction * resistance_reduction)
                </p>
                <p>
                  Both Base Damage and Armor-Piercing Damage are affected by
                  resistances
                </p>
                <p>
                  Against every attack, all aplicable resistances are added (up
                  to a maximum of 90, minimum of -100)
                </p>
              </Fragment>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Magic Resistance",
          field: "damage_mod_magic",
          synonyms: ["magical_resist", "magical_res"],
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/resistance_magic.webp",
            headerTooltip: (props) => (
              <Fragment>
                <p>Reduces any "Magical" Damage received</p>
                <p>
                  Resistances are applied independently of armor (dmg *
                  armor_reduction * resistance_reduction)
                </p>
                <p>
                  Both Base Damage and Armor-Piercing Damage are affected by
                  resistances
                </p>
                <p>
                  Against every attack, all aplicable resistances are added (up
                  to a maximum of 90, minimum of -100)
                </p>
              </Fragment>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Fire Resistance/Weakness",
          field: "damage_mod_flame",
          synonyms: ["fire_resist", "fire_res", "fire_weakness", "fire_weak"],
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/resistance_fire.webp",
            headerTooltip: (props) => (
              <Fragment>
                <p>Reduces/Increases any "Flaming" Damage received</p>
                <p>
                  Resistances are applied independently of armor (dmg *
                  armor_reduction * resistance_reduction)
                </p>
                <p>
                  Both Base Damage and Armor-Piercing Damage are affected by
                  resistances
                </p>
                <p>
                  Against every attack, all aplicable resistances are added (up
                  to a maximum of 90, minimum of -100)
                </p>
              </Fragment>
            ),
          },
        },
      ],
    },
    {
      type: ["integerColumn"],
      headerName: "Leadership",
      field: "leadership",
      synonyms: ["morale"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_stat_morale.webp",
      },
    },
    {
      groupId: "speed_group",
      openByDefault: localStorage.getItem("speed_group") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["integerColumn"],
          headerName: "Speed",
          field: "speed",
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_speed.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Run Speed",
          field: "run_speed",
          headerComponentParams: {
            icon: "ui/skins/default/icon_status_fatigue_24px.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Fly Speed",
          field: "fly_speed",
          synonyms: ["flying_speed"],
          headerComponentParams: {
            // icon: "ui/campaign%20ui/skills/mount_beaquis.webp"
            fa: "plane",
          },
        },
      ],
    },
    {
      groupId: "attack_group",
      openByDefault: localStorage.getItem("attack_group") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          headerName: "Melee Attack [MA]",
          field: "melee_attack",
          synonyms: ["ma", "attack"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_attack.webp",
          },
          cellRenderer: "MeleeContactPhaseRenderer",
          minWidth: 64,
          width: 64,
          maxWidth: 64,
        },
        {
          columnGroupShow: "open",
          type: ["magicAttack"],
          headerName: "Melee Is Magical",
          field: "primary_melee_weapon.is_magical",
          synonyms: ["is_magical", "magical_attack"],
        },
        {
          columnGroupShow: "open",
          type: ["flamingAttack"],
          headerName: "Melee is Flaming",
          field: "primary_melee_weapon.ignition_amount",
          synonyms: ["is_flaming", "flaming_attack"],
        },
        {
          columnGroupShow: "open",
          type: ["phaseColumn"],
          headerName: "Melee Phase",
          field: "primary_melee_weapon.phase",
          synonyms: ["phase"],
        },
        {
          type: ["booleanColumn"],
          headerName: "Siege Attacker",
          field: "can_siege",
          synonyms: ["siege"],
          headerComponentParams: {
            icon: "ui/battle ui/ability_icons/can_siege.webp",
            headerTooltip: (props) => (
              <Fragment>
                <p>
                  This unit can attack city gates, allowing you to instantly
                  launch a siege battle without having to wait for towers or
                  batterig rams to be built.
                </p>
              </Fragment>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Melee Attack Interval",
          field: "primary_melee_weapon.melee_attack_interval",
          synonyms: ["attack_speed", "attack_rate"],
          headerComponentParams: {
            // fa: 'stopwatch',
            icon: "ui/skins/default/icon_status_melee_24px.webp",
            facolor: "white",
            headerTooltip: (props) => (
              <>
                <p>
                  The attack interval is used to control the impact of
                  animations on attack frequency (e.g. a unit with a long attack
                  animation could be given a better attack frequency to match
                  similar units with a faster attack animation or vice-versa)
                </p>
                <p>
                  As such, a difference in attack interval between two units
                  does NOT necessarily translate into a difference in "attack
                  speed".
                </p>
              </>
            ),
          },
        },
        {
          type: ["entitySizeColumn"],
          columnGroupShow: "open",
          headerName: "Splash Attack Target Size",
          field: "primary_melee_weapon.splash_attack_target_size",
          headerComponentParams: {
            // icon: additionalIcons[2]
            fa: "street-view",
            headerTooltip: (props) => (
              <p>
                This unit can do Splash Attacks against entities up to this
                size. Blank value means this unit cannot use Splash Attacks.
              </p>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Splash Attack Max Attacks",
          field: "primary_melee_weapon.splash_attack_max_attacks",
          headerComponentParams: {
            // icon: additionalIcons[3]
            fa: "users",
            headerTooltip: (props) => (
              <Fragment>
                <p>
                  The maximum number of entities this unit can hit with a single
                  Splash Attack.
                </p>
                <p>
                  Splash attack area is variable and determined by attack
                  animations (i.e. unrelated to this value).{" "}
                </p>
                <p>
                  Splash damage is always weapon damage spread across all
                  entities in target area up to max targets.
                </p>
                <p>
                  Each attack in a splash damage area has its own hit chance.
                </p>
              </Fragment>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Splash Attack Power Multiplier",
          field: "primary_melee_weapon.splash_attack_power_multiplier",
          headerComponentParams: {
            icon: additionalIcons[4],
            headerTooltip: (props) => (
              <Fragment>
                <p>
                  Affects the physical force of the splash attack (how much
                  targets are thrown around)
                </p>
                <p>No impact on actual damage AFAIK</p>
              </Fragment>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Is High Threat",
          field: "is_high_threat",
          headerComponentParams: {
            icon: "ui/skins/default/icon_status_alert_high_24px.webp",
            headerTooltip: (props) => (
              <Fragment>
                <p>
                  When a splash attack hits one or more "High Threat" entity,
                  ONE of the "High Threat" entity will be chosen to receive ALL
                  the damage.
                </p>
                <p>
                  This overrides the normal splash attack behavior of splitting
                  the damage between all hit entities.
                </p>
                <p>
                  This is NOT related to the "threat marker" located above units
                  in game.
                </p>
              </Fragment>
            ),
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Weapon Length",
          field: "primary_melee_weapon.weapon_length",
          headerComponentParams: {
            icon: additionalIcons[1],
          },
        },
      ],
    },
    {
      groupId: "defence_group",
      openByDefault: localStorage.getItem("defence_group") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["integerColumn"],
          headerName: "Melee Defence [MD]",
          field: "melee_defence",
          synonyms: ["defence", "md"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_defence.webp",
            tooltipDesc: MeleeDefenceTooltip,
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Hit Reactions Ignore Chance",
          field: "hit_reactions_ignore_chance",
          headerComponentParams: {
            // icon: additionalIcons[4]
            fa: "hand-rock",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Knock Interrupts Ignore Chance",
          field: "knock_interrupts_ignore_chance",
          headerComponentParams: {
            // icon: additionalIcons[4]
            fa: "hand-rock",
          },
        },
      ],
    },
    {
      groupId: "dmg_group",
      openByDefault: localStorage.getItem("dmg_group") === "true",
      headerName: "",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["integerColumn"],
          headerName: "Weapon Strength",
          field: "primary_melee_weapon.damage",
          synonyms: ["ws"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_damage.webp",
          },
          cellClass: (params) => {
            const compoundStatsClass =
              params.data["delta_" + params.colDef.field] === undefined
                ? null
                : params.data["delta_" + params.colDef.field] > 0
                ? "increased"
                : params.data["delta_" + params.colDef.field] < 0
                ? "reduced"
                : null;
            let relatedStatsClass =
              params.data["delta_base_damage"] === undefined
                ? null
                : "adjusted";
            relatedStatsClass =
              relatedStatsClass ||
              (params.data["delta_ap_damage"] === undefined
                ? null
                : "adjusted");
            relatedStatsClass =
              relatedStatsClass ||
              (params.data["delta_bonus_v_large"] === undefined
                ? null
                : "adjusted");
            relatedStatsClass =
              relatedStatsClass ||
              (params.data["delta_bonus_v_infantry"] === undefined
                ? null
                : "adjusted");
            return compoundStatsClass || relatedStatsClass || "";
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Base Damage",
          field: "primary_melee_weapon.base_damage",
          synonyms: ["melee_damage", "melee_base_damage"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_damage.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Armour Piercing Damage",
          field: "primary_melee_weapon.ap_damage",
          synonyms: ["ap", "melee_ap", "melee_ap_damage"],
          headerComponentParams: {
            icon: "ui/skins/default/modifier_icon_armour_piercing.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "AP/TotalDmg Ratio",
          field: "primary_melee_weapon.ap_ratio",
          synonyms: ["ap ratio"],
          headerComponentParams: {
            fa: "percent",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Bonus vs. Large",
          field: "primary_melee_weapon.bonus_v_large",
          headerComponentParams: {
            icon: "ui/skins/default/modifier_icon_bonus_vs_large.webp",
            tooltipDesc: (props) => {
              const bonusMA = props.value;
              const bonusDmg = Math.round(
                (props.value * props.data.primary_melee_weapon.base_damage) /
                  (props.data.primary_melee_weapon.base_damage +
                    props.data.primary_melee_weapon.ap_damage)
              );
              const bonusAP = Math.round(
                (props.value * props.data.primary_melee_weapon.ap_damage) /
                  (props.data.primary_melee_weapon.base_damage +
                    props.data.primary_melee_weapon.ap_damage)
              );

              return (
                <TooltipDesc title="Bonus vs. Large">
                  <p>
                    +{bonusMA} Melee Attack ({props.data.melee_attack + bonusMA}{" "}
                    total)
                  </p>
                  <p>
                    +{bonusDmg} Damage (
                    {props.data.primary_melee_weapon.base_damage + bonusDmg}{" "}
                    total)
                  </p>
                  <p>
                    +{bonusAP} AP Damage (
                    {props.data.primary_melee_weapon.ap_damage + bonusAP} total)
                  </p>
                  <p>Large Units: [Is Large = 1]</p>
                </TooltipDesc>
              );
            },
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Bonus vs. Infantry",
          field: "primary_melee_weapon.bonus_v_infantry",
          headerComponentParams: {
            icon: "ui/skins/default/modifier_icon_bonus_vs_infantry.webp",
            tooltipDesc: (props) => {
              const bonusMA = props.value;
              const bonusDmg = Math.round(
                (props.value * props.data.primary_melee_weapon.base_damage) /
                  (props.data.primary_melee_weapon.base_damage +
                    props.data.primary_melee_weapon.ap_damage)
              );
              const bonusAP = Math.round(
                (props.value * props.data.primary_melee_weapon.ap_damage) /
                  (props.data.primary_melee_weapon.base_damage +
                    props.data.primary_melee_weapon.ap_damage)
              );

              return (
                <TooltipDesc title="Bonus vs. Infantry">
                  <p>
                    +{bonusMA} Melee Attack ({props.data.melee_attack + bonusMA}{" "}
                    total)
                  </p>
                  <p>
                    +{bonusDmg} Damage (
                    {props.data.primary_melee_weapon.base_damage + bonusDmg}{" "}
                    total)
                  </p>
                  <p>
                    +{bonusAP} AP Damage (
                    {props.data.primary_melee_weapon.ap_damage + bonusAP} total)
                  </p>
                  <p>Infantry Units (aka 'Small' Units): [Is Large = 0]</p>
                </TooltipDesc>
              );
            },
          },
        },
      ],
    },
    {
      groupId: "charge_group",
      openByDefault: localStorage.getItem("charge_group") === "true",
      headerName: "",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["integerColumn"],
          headerName: "Charge Bonus",
          field: "charge_bonus",
          synonyms: ["cb", "charge"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_charge_bonus.webp",
            tooltipDesc: (props) => {
              const bonusMA = props.value;
              const bonusDmg = Math.round(
                (props.value * props.data.primary_melee_weapon.base_damage) /
                  (props.data.primary_melee_weapon.base_damage +
                    props.data.primary_melee_weapon.ap_damage)
              );
              const bonusAP = Math.round(
                (props.value * props.data.primary_melee_weapon.ap_damage) /
                  (props.data.primary_melee_weapon.base_damage +
                    props.data.primary_melee_weapon.ap_damage)
              );

              return (
                <TooltipDesc title="Charge Bonus">
                  <p>
                    +{bonusMA} Melee Attack ({props.data.melee_attack + bonusMA}{" "}
                    total)
                  </p>
                  <p>
                    +{bonusDmg} Damage (
                    {props.data.primary_melee_weapon.base_damage + bonusDmg}{" "}
                    total)
                  </p>
                  <p>
                    +{bonusAP} AP Damage (
                    {props.data.primary_melee_weapon.ap_damage + bonusAP} total)
                  </p>
                  <p>Buffs linearly fade away over 13s</p>
                </TooltipDesc>
              );
            },
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Charge Speed",
          field: "charge_speed",
          headerComponentParams: {
            // icon: additionalIcons[5]
            fa: "long-arrow-alt-right",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Flying Charge Speed",
          field: "flying_charge_speed",
          headerComponentParams: {
            // icon: additionalIcons[5]
            fa: "long-arrow-alt-right",
          },
        },
        // TODO #pack_assemblykit_discrepency
        // {
        //   columnGroupShow: "open",
        //   type: ['integerColumn'],
        //   headerName: "Charge Distance Commence Run",
        //   field: "charge_distance_commence_run",
        //   headerComponentParams: {
        //     // icon: additionalIcons[5]
        //     fa: 'flag',
        //   },
        // },
        // TODO #pack_assemblykit_discrepency
        // {
        //   columnGroupShow: "open",
        //   type: ['integerColumn'],
        //   headerName: "Charge Distance Adopt Charge Pose",
        //   field: "charge_distance_adopt_charge_pose",
        //   headerComponentParams: {
        //     // icon: additionalIcons[5],
        //     fa: 'arrows-alt-h',
        //   },
        // },
        // TODO #pack_assemblykit_discrepency
        // {
        //   columnGroupShow: "open",
        //   type: ['integerColumn'],
        //   headerName: "Charge Distance Pick Target",
        //   field: "charge_distance_pick_target",
        //   headerComponentParams: {
        //     icon: 'ui/skins/default/icon_distance_to_target.webp',
        //   },
        // },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Collision Attack Max Targets",
          field: "primary_melee_weapon.collision_attack_max_targets",
          headerComponentParams: {
            // icon: additionalIcons[5]
            fa: "users",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Collision Attack Max Targets Cooldown",
          field: "primary_melee_weapon.collision_attack_max_targets_cooldown",
          headerComponentParams: {
            icon: "ui/skins/default/icon_cooldown.webp",
          },
        },
      ],
    },
    ...missileColdefs("primary_missile_weapon"),
    ...(globalSettings().show_secondary_missile_weapon === "true"
      ? missileColdefs("secondary_missile_weapon")
      : []),
    {
      type: ["integerColumn"],
      headerName: "Multiplayer Cost",
      field: "multiplayer_cost",
      synonyms: ["cost", "mp_cost"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_treasury.webp",
      },
      maxWidth: 52,
      width: 52,
      minWidth: 52,
    },
    {
      type: ["integerColumn"],
      headerName: "Campaign Cost",
      field: "singleplayer_cost",
      synonyms: ["sp_cost"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_income.webp",
      },
      maxWidth: 52,
      width: 52,
      minWidth: 52,
    },
    {
      type: ["integerColumn"],
      headerName: "Upkeep",
      field: "singleplayer_upkeep",
      headerComponentParams: {
        icon: "ui/skins/default/icon_upkeep.webp",
      },
      maxWidth: 52,
      width: 52,
      minWidth: 52,
    },
    {
      type: ["integerColumn"],
      headerName: "Create Time",
      field: "create_time",
      synonyms: ["build_time"],
      headerComponentParams: {
        icon: "ui/campaign%20ui/effect_bundles/turns.webp",
      },
      maxWidth: 52,
      width: 52,
      minWidth: 52,
    },
    {
      headerName: "Category",
      field: "category",
      headerComponentParams: {
        icon: "ui/skins/default/unit_cat_holder_round.webp",
      },
      cellRenderer: "UiUnitGroupIconRenderer",
      maxWidth: 36,
      width: 36,
      minWidth: 36,
    },
    {
      headerName: "Special Category",
      field: "special_category",
      synonyms: ["sc"],
      headerComponentParams: {
        icon: "ui/skins/default/unit_cat_holder_round_renown.webp",
      },
      cellRenderer: "SpecialCategoryRenderer",
      maxWidth: 36,
      width: 36,
      minWidth: 36,
    },
    {
      groupId: "unit_attributes",
      openByDefault: localStorage.getItem("unit_attributes") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          columnGroupShow: "closed",
          headerName: "Attributes ",
          valueGetter: (params) => "Expand group to view",
          maxWidth: 106,
          width: 106,
          minWidth: 106,
        },
        {
          columnGroupShow: "open",
          headerName: "Attributes",
          field: "attributes",
          synonyms: ["attribute"],
          cellRenderer: "AttributesRenderer",
          valueFormatter: (params) =>
            _.reduce(params.value, (v, a) => (v += `${a.key}, `), ""),
          maxWidth: 106,
          width: 106,
          minWidth: 106,
          getQuickFilterText: (params) =>
            _.map(params.value, (a) => a.bullet_text),
        },
      ],
    },
    {
      groupId: "unit_abilities",
      openByDefault: localStorage.getItem("unit_abilities") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          columnGroupShow: "closed",
          headerName: "Abilities ",
          valueGetter: (params) => "Expand group to view",
          maxWidth: 106,
          width: 106,
          minWidth: 106,
        },
        {
          columnGroupShow: "open",
          headerName: "Abilities",
          field: "abilities",
          synonyms: ["ability"],
          cellRenderer: "AbilitiesRenderer",
          valueFormatter: (params) =>
            _.reduce(params.value, (v, a) => (v += `${a.name}, `), ""),
          maxWidth: 204,
          width: 204,
          minWidth: 204,
          getQuickFilterText: (params) => _.map(params.value, (a) => a.name),
        },
      ],
    },
    {
      groupId: "unit_spells",
      openByDefault: localStorage.getItem("unit_spells") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          columnGroupShow: "closed",
          headerName: "Spells ",
          valueGetter: (params) => "Expand group to view",
          maxWidth: 106,
          width: 106,
          minWidth: 106,
        },
        {
          columnGroupShow: "open",
          headerName: "Spells",
          field: "spells",
          synonyms: ["spell"],
          cellRenderer: "AbilitiesRenderer",
          valueFormatter: (params) =>
            _.reduce(params.value, (v, a) => (v += `${a.name}, `), ""),
          maxWidth: 268,
          width: 268,
          minWidth: 268,
          getQuickFilterText: (params) => _.map(params.value, (a) => a.name),
        },
      ],
    },
    // {
    //   groupId: 'unit_items',
    //   openByDefault: localStorage.getItem('unit_items') === 'true',
    //   headerGroupComponent: "ColumnGroupHeaderRenderer",
    //   children: [
    //     {
    //       columnGroupShow: "closed",
    //       headerName: 'Item Abilities',
    //       valueGetter: params => 'Expand group to view',
    //       maxWidth: 106,
    //       width: 106,
    //       minWidth: 106,
    //     },
    //     {
    //       columnGroupShow: "open",
    //       headerName: 'Item Abilities',
    //       field: "items",
    //       synonyms: ['item'],
    //       cellRenderer: "AbilitiesRenderer",
    //       valueFormatter: params => _.reduce(params.value, (v, a) => v += `${a.name}, `, ''),
    //       maxWidth: 268,
    //       width: 268,
    //       minWidth: 268,
    //       getQuickFilterText: params => _.map(params.value, a => a.name),
    //     },
    //   ]
    // },
    {
      type: ["keyColumn"],
      field: "key",
    },
  ];

  return colDefs;
}
