import React from "react";

export default () => (
  <div className={"changelog"}>
    <h2>5.0.0</h2>
    <ul>
      <li>
        5.2.3
        <ul>
          <li>Patch 5.2.3 added!</li>
          <li>
            Updated mods data to the latest available as of 2024.09.10
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
          <li>Added "Tier" colum to Units Grid</li>
        </ul>
      </li>
      <li>
        5.2.1
        <ul>
          <li>Patch 5.2.1 added!</li>
          <li>
            Updated mods data to the latest available as of 2024.08.25
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        5.2.0
        <ul>
          <li>Patch 5.2.0 added!</li>
          <li>
            Updated mods data to the latest available as of 2024.08.20
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        5.1.2
        <ul>
          <li>Patch 5.1.2 added!</li>
          <li>
            Updated mods data to the latest available as of 2024.07.30
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        5.1.1
        <ul>
          <li>Patch 5.1.1 added!</li>
          <li>
            Updated mods data to the latest available as of 2024.07.15
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        5.1.0
        <ul>
          <li>
            5.1.0.1
            <ul>
              <li>
                BUG: Missing units and/or misplaced units in the unit card
                finder: FIXED
              </li>
            </ul>
          </li>
          <li>Patch 5.1.0 added!</li>
          <li>
            Updated mods data to the latest available as of 2024.06.29
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        5.0.4
        <ul>
          <li>Patch 5.0.4 added!</li>
          <li>
            Updated mods data to the latest available as of 2024.06.07
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
          <li>FIXED: Attributes column crashes the grid</li>
        </ul>
      </li>
      <li>
        5.0.3
        <ul>
          <li>Patch 5.0.3 added!</li>
          <li>
            Updated mods data to the latest available as of 2024.05.14
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        5.0.2
        <ul>
          <li>
            Updated mods data to the latest available as of 2024.05.12
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        5.0.0
        <ul>
          <li>Patch 5.0.0 - Throne of Decay version added!</li>
          <li>
            Updated mods data to the latest available as of 2024.04.30
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2>4.0.0</h2>
    <ul>
      <li>
        4.2.3
        <ul>
          <li>Patch 4.2.2 version added!</li>
          <li>Patch 4.2.0 version removed :(</li>
          <li>
            Updated mods data to the latest available as of 2024.03.23
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
          <li>Unit Cards: Added "Projectile Penetration Entity Size Cap"</li>
          <li>Unit Cards: Added "Projectile Penetration Max Penetration"</li>
        </ul>
      </li>
      <li>
        4.2.2
        <ul>
          <li>Patch 4.2.1 version added!</li>
          <li>
            Updated mods data to the latest available as of 2024.03.09
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
          <li>BUG: images in tooltips are not showing up properly: FIXED</li>
        </ul>
      </li>
      <li>
        4.2.1
        <ul>
          <li>BUG: Cathay shows up twice in unit finder: FIXED</li>
          <li>
            Overcharged version of spells will now appear in the tooltip if they
            exist
          </li>
        </ul>
      </li>
      <li>
        4.2.0
        <ul>
          <li>Patch 4.2.0 version added!</li>
          <li>Patch 4.1.1 version removed</li>
          <li>Patch 4.0.7 version removed</li>
          <li>
            Updated mods data to the latest available as of 2024.02.25
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        4.1.4
        <ul>
          <li>
            Changed image handling to (hopefully!) fit withing the new
            cloudinary free plan limits.
          </li>
        </ul>
      </li>
      <li>
        4.1.3
        <ul>
          <li>Patch 4.1.4 version added!</li>
          <li>Update 3.1.0 version removed</li>
          <li>
            Updated mods data to the latest available as of 2024.02.03
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        4.1.2
        <ul>
          <li>Added "Charge speed" to unit cards</li>
        </ul>
      </li>
      <li>
        4.1.1
        <ul>
          <li>Patch 4.1.2 version added!</li>
          <li>
            Updated mods data to the latest available as of 2023.12.03
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        4.1.0
        <ul>
          <li>Patch 4.1.1 version added!</li>
          <li>Patch 3.0.1 version removed</li>
          <li>
            Updated mods data to the latest available as of 2023.11.23
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        4.0.5
        <ul>
          <li>Patch 4.0.7 version added!</li>
          <li>
            Patch 4.0.3 version removed (data was the same as Patch 4.0.7)
          </li>
          <li>
            Updated mods data to the latest available as of 2023.11.05
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        4.0.4
        <ul>
          <li>
            NEW: Added "Projectile Category" to both the unit grid and unit
            cards
          </li>
          <li>
            FIXED: few minor display errors on things like, eg, Invocation of
            Nehek on the ability card (shows tr: morale text)
          </li>
          <li>
            FIXED: damage_chance no longer exists in WH3. Replaced with a static
            50%.
          </li>
        </ul>
      </li>
      <li>
        4.0.3
        <ul>
          <li>Patch 4.0.3 version added!</li>
          <li>
            Patch 4.0.2 version removed (data was the same as Patch 4.0.3)
          </li>
          <li>
            [BUG] Mother Ostankya and the Hags are not visible under Kislev in
            the Card Selector: FIXED!
          </li>
        </ul>
      </li>
      <li>
        4.0.2
        <ul>
          <li>Patch 4.0.2 version added!</li>
          <li>Patch 4.0 version removed (data was the same as Patch 4.0.2)</li>
          <li>Update 2.4.0 version removed</li>
          <li>
            Updated mods data to the latest available as of 2023.09.11
            <ul>
              <li>Radious (tww3)</li>np
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>Patch 4.0 version added!</li>
      <li>
        Updated mods data to the latest available as of 2023.08.31
        <ul>
          <li>Radious (tww3)</li>np
          <li>SFO (tww3)</li>
          <li>The Duke's Damned Nations (tww3)</li>
        </ul>
      </li>
    </ul>
    <h2>3.10.0</h2>
    <ul>
      <li>
        3.10.5
        <ul>
          <li>
            Updated mods data to the latest available as of 2023.08.24
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        3.10.4
        <ul>
          <li>
            Updated mods data to the latest available as of 2023.07.13
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        3.10.3
        <ul>
          <li>
            Updated mods data to the latest available as of 2023.07.06
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        3.10.2
        <ul>
          <li>
            Updated mods data to the latest available as of 2023.06.01
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        3.10.1
        <ul>
          <li>[BUG] Missing mount name: FIXED</li>
          <li>
            [BUG] Mass displayed is wrong: FIXED (Thanks @Bogdanov89 from our
            Discord channel for pointing it out!)
          </li>
          <li>
            Updated mods data to the latest available as of 2023.05.29
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>Update 3.1.0 version added!</li>
      <li>
        Updated mods data to the latest available as of 2023.05.26
        <ul>
          <li>Radious (tww3)</li>
          <li>SFO (tww3)</li>
          <li>The Duke's Damned Nations (tww3)</li>
        </ul>
      </li>
    </ul>
    <h2>3.9.0</h2>
    <ul>
      <li>
        3.9.5
        <ul>
          <li>
            Updated mods data to the latest available as of 2023.05.17
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        3.9.4
        <ul>
          <li>Update 3.0.1 version added! (No data changes AFAIK)</li>
          <li>
            Updated mods data to the latest available as of 2023.05.11
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        3.9.3
        <ul>
          <li>
            Fixed export code for TWW3 mods. Data should be correct now. Thanks
            for your patience :)
          </li>
          <li>
            Updated mods data to the latest available as of 2023.04.27
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        3.9.2
        <ul>
          <li>
            Updated mods data to the latest available as of 2023.04.20
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        3.9.1
        <ul>
          <li>
            Updated mods data to the latest available as of 2023.04.16
            <ul>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>Update 3.0.0 version added!</li>
      <li>Update 2.2.0 version removed</li>
      <li>
        Updated mods data to the latest available as of 2023.04.15
        <ul>
          <li>Radious (tww3)</li>
          <li>SFO (tww3)</li>
          <li>The Duke's Damned Nations (tww3)</li>
        </ul>
      </li>
    </ul>
    <h2>3.8.0</h2>
    <ul>
      <li>
        3.8.1
        <ul>
          <li>
            Updated mods data to the latest available as of 2023.03.10
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>Update 2.4.0 version added!</li>
      <li>Update 2.1.0 version removed</li>
      <li>
        Updated mods data to the latest available as of 2023.02.16
        <ul>
          <li>Radious (tww3)</li>
          <li>SFO (tww3)</li>
          <li>The Duke's Damned Nations (tww3)</li>
        </ul>
      </li>
    </ul>
    <h2>3.7.0</h2>
    <ul>
      <li>
        3.7.4
        <ul>
          <li>
            Updated mods data to the latest available as of 2023.02.01
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        3.7.3
        <ul>
          <li>
            Updated mods data to the latest available as of 2023.01.16
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The Duke's Damned Nations (tww3)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        3.7.2
        <ul>
          <li>Updated "Radious Total War Mod WH3" data to 2022.11.25</li>
        </ul>
      </li>
      <li>
        3.7.1
        <ul>
          <li>Fixed date in TWW3 mods name</li>
          <li>Updated "The Duke's Damned Nations" data to 2022.11.23</li>
        </ul>
      </li>
      <li>Update 2.3.0 version added!</li>
      <li>Update 2.0.0 version removed :(</li>
      <li>TWW2 mods are back!</li>
      <li>
        Updated mods data to the latest available as of 2022.11.22
        <ul>
          <li>Radious (tww3)</li>
          <li>SFO (tww3)</li>
          <li>The Duke's Damned Nations (tww3)</li>
        </ul>
      </li>
    </ul>
    <h2>3.6.0</h2>
    <ul>
      <li>
        3.6.2
        <ul>
          <li>
            Added "Ground Stat Effect" to the unit card (bottom of the card,
            between "Mass" and "Fatigue modifier"). Check tooltip for ground
            modifiers for the given unit.
          </li>
        </ul>
      </li>
      <li>
        3.6.1
        <ul>
          <li>
            Re-enabling some TWW2 Mods (Radious and SFO). Others should be
            re-enabled in about a month if all goes well.
          </li>
          <li>
            Updated Radious (tww3) mod data to the latest availalble as of
            2022.10.28
          </li>
          <li>Adding "The Duke`s Damned Nation" mod for TWW3</li>
        </ul>
      </li>
      <li>Update 2.2.0 version added!</li>
      <li>
        Updated mods data to the latest available as of 2022.10.19
        <ul>
          <li>Radious (tww3)</li>
          <li>SFO (tww3)</li>
        </ul>
      </li>
    </ul>
    <h2>3.5.0</h2>
    <ul>
      <li>
        3.5.1
        <ul>
          <li>Re-enabling TWW2 (TWW2 mods still disabled for now)</li>
          <li>
            Updated Radious (tww3) mod data to the latest availalble as of
            2022.09.27
          </li>
        </ul>
      </li>
      <li>Update 2.1.0 version added!</li>
      <li>Older TWW3 versions removed</li>
      <li>
        Updated mods data to the latest available as of 2022.09.06
        <ul>
          <li>Radious (tww3)</li>
          <li>SFO (tww3)</li>
        </ul>
      </li>
      <li>
        TWW2 versions and its mods have been temporary removed while I address a
        bandwith issue
      </li>
    </ul>
    <h2>3.4.0</h2>
    <ul>
      <li>Update 2.0.0 version added!</li>
      <li>Older TWW3 versions removed</li>
      <li>
        Updated the "Faction selector" in the Units Cards for TWW3 to show
        individual "cultures" instead of listing all factions to match the
        in-game UX.
      </li>
    </ul>
    <h2>3.3.0</h2>
    <ul>
      <li>
        3.3.1
        <ul>
          <li>Update 1.3.1 version added!</li>
          <li>
            Updated mods data to the latest available as of 2022.07.17
            <ul>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>The End Times (tww2)</li>
            </ul>
          </li>
          <li>
            [BUG] Hovering over certian abilities will crash the site: FIXED
          </li>
        </ul>
      </li>
      <li>Update 1.3.0 version added!</li>
      <li>
        Updated mods data to the latest available as of 2022.07.02
        <ul>
          <li>Radious (tww3)</li>
          <li>SFO (tww3)</li>
        </ul>
      </li>
      <li>[BUG] Campaign Difficulties not loading: FIXED</li>
    </ul>
    <h2>3.2.0</h2>
    <ul>
      <li>
        3.2.1
        <ul>
          f
          <li>
            Updated mods data to the latest available as of 2022.05.31
            <ul>
              <li>SFO</li>
              <li>Radious (tww3)</li>
              <li>SFO (tww3)</li>
              <li>Radious (tww2)</li>
              <li>The End Times (tww2)</li>
              <li>SFO (tww2)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>Update 1.2.0 version added!</li>
      <li>
        Added mod data for TWW3. This is still a work in progress as I am not
        100% convinced my export tool is working as intended.
        <ul>
          <li>SFO</li>
          <li>Radious</li>
        </ul>
      </li>
    </ul>
    <h2>3.1.0</h2>
    <ul>
      <li>Update 1.1.0 version added!</li>
      <li>Added barriers (Tzeentch) to unit cards and unit grid</li>
      <li>
        Now showing the selected unit size in the page header on relevant pages.
        You can also click it to access the settings modal faster.
      </li>
      <li>
        Updated the mass computation to match the in-game values. This will
        mostly affect chariots and cavalry.
      </li>
      <li>[BUG] Faction icons not showing up: FIXED</li>
      <li>[BUG] Kislev units not showing up in Units Grid: FIXED</li>
      <li>
        [BUG] Single-entity stats not scaled properly based on unit size in
        TWW3: FIXED
      </li>
    </ul>
    <h2>3.0.0</h2>
    <ul>
      <li>Total War: Warhammer III data is finally available!!!</li>
      <li>
        TWW2 versions cleanup
        <ul>
          <li>
            Cavalry Beta -> Renamed to "Latest" (same data, the name was just
            confusing)
          </li>
          <li>Patch 1.12.1 -> Removed</li>
          <li>The Silence and the Fury -> Removed</li>
          <li>Patch 1.11.1 -> Removed</li>
          <li>The Twisted & Twilight update V2 -> Removed</li>
        </ul>
      </li>
      <li>
        3.0.1
        <ul>
          <li>Added stat scaling for single entities based off unit size</li>
          <li>
            Removed adds for now as they seem to make the site loading buggy
            sometimes
          </li>
        </ul>
      </li>
    </ul>
    <h2>2.13.0</h2>
    <ul>
      <li>
        Updated mods data to the latest available as of 2022.02.20
        <ul>
          <li>SFO</li>
          <li>Radious</li>
          <li>CTT</li>
          <li>The End Times</li>
        </ul>
      </li>
    </ul>
    <h2>2.12.0</h2>
    <ul>
      <li>Cavalry Beta version added!</li>
      <li>
        Mod data updated to the latest available as of 2021.11.06
        <ul>
          <li>SFO</li>
          <li>Radious</li>
          <li>CTT</li>
          <li>The End Times</li>
        </ul>
      </li>
    </ul>
    <h2>2.11.0</h2>
    <ul>
      <li>Patch 1.12.1 version added!</li>
      <li>
        Mod data updated to the latest available as of 2021.09.16
        <ul>
          <li>SFO</li>
          <li>Radious</li>
          <li>CTT</li>
          <li>The End Times</li>
        </ul>
      </li>
      <li>
        Updated the caching behaviours of some queries. This MIGHT result in
        slightly slower response times... I will monitor the site and see if
        things are acceptable.
      </li>
    </ul>
    <h2>2.10.0</h2>
    <ul>
      <li>
        2.10.3
        <ul>
          <li>
            Clicking on the version on a Unit Card will now open the Unit Finder
            dialog with that version already selected
          </li>
          <li>
            Clicking on a faction icon on a Unit Card will now open the Unit
            Finder dialog with that faction and the unit's version already
            selected
          </li>
        </ul>
      </li>
      <li>
        2.10.2
        <ul>
          <li>Radious version now includes "Radious Total War Mod - Part 3"</li>
          <li>
            Mod data updated to the latest available as of 2021.07.30
            <ul>
              <li>SFO</li>
              <li>Radious</li>
              <li>The End Times</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        2.10.1
        <ul>
          <li>
            [BUG] Master Rune abilities are not marked as "Overcast": FIXED
            (Thanks to ASD in discord for reporting!)
          </li>
        </ul>
      </li>
      <li>The Silence & The Fury version added!</li>
      <li>
        Some older versions have been removed
        <ul>
          <li>The Rakarth Update (1.11.0)</li>
          <li>Da Nutz ‘N’ Boltz Update (1.9.2)</li>
        </ul>
      </li>
      <li>
        Mod data updated to the latest available as of 2021.07.24
        <ul>
          <li>SFO</li>
          <li>Radious</li>
          <li>CTT</li>
          <li>The End Times</li>
        </ul>
      </li>
    </ul>
    <h2>2.9.0</h2>
    <ul>
      <li>
        2.9.3
        <ul>
          <li>
            "Patch 1.11.1" version added! (but there isn`t really any changes to
            unit stats as as far as I can tell)
          </li>
          <li>
            Updated mods data to the latest available as of 2021.05.7
            <ul>
              <li>SFO</li>
              <li>Radious</li>
              <li>CTT</li>
              <li>The End Times</li>
            </ul>
          </li>
          <li>
            2.9.3.1
            <ul>
              <li>
                Added "Projectile Explosion Detonation Radius" column to
                missiles in Abilities Grid
              </li>
            </ul>
          </li>
          <li>
            2.9.3.2
            <ul>
              <li>
                Added "Projectile Bonus vs. Infantry" column to missiles in
                Abilities Grid
              </li>
              <li>
                Added "Projectile Bonus vs. Large" column to missiles in
                Abilities Grid
              </li>
              <li>Fixed translations in attribute tooltips</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        2.9.2
        <ul>
          <li>
            [BUG] Unit grid crashing and unit grid search not working: Fixed!
          </li>
          <li>
            Updated mods data to the latest available as of 2021.03.25
            <ul>
              <li>SFO</li>
              <li>Radious</li>
              <li>CTT</li>
              <li>The End Times</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        2.9.1
        <ul>
          <li>
            Missile damage now correctly uses burst_size to compute its value.
            Shots per volley
          </li>
          <li>
            Campaign Exclusive units now have their own group in the Unit
            Finder.
          </li>
          <li>
            Updated mods data to the latest available as of 2021.03.25
            <ul>
              <li>SFO</li>
              <li>Radious</li>
              <li>CTT</li>
              <li>The End Times</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>"The Rakarth Update" version added!</li>
      <li>
        Did a bit of cleanup in the versions to keep the site leaner
        <ul>
          <li>"The Warden & The Paunch (1.9.0)" version removed</li>
          <li>"Skulls for the Skull Throne 4 (1.9.1)" version removed</li>
          <li>"The Twisted & Twilight Update (1.10.0)" version removed</li>
          <li>"The Asrai Resurgent Update (1.10.1)" version removed</li>
        </ul>
      </li>
      <li>
        Updated mods data to the latest available as of 2021.03.20
        <ul>
          <li>SFO</li>
          <li>CTT</li>
          <li>The End Times</li>
        </ul>
      </li>
    </ul>
    <h2>2.8.0</h2>
    <ul>
      <li>
        2.8.6
        <ul>
          <li>
            [BUG] Unit finder crashes when using keyword to search units that
            are not availble in multiplayer: FIXED
          </li>
          <li>
            Updated mods data to the latest available as of 2021.03.06
            <ul>
              <li>SFO</li>
              <li>Radious</li>
              <li>CTT</li>
              <li>The End Times</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        2.8.5
        <ul>
          <li>"The Twisted & Twilight Update v2" version added!</li>
          <li>
            Updated mods data to the latest available as of 2021.02.09
            <ul>
              <li>SFO</li>
              <li>Radious</li>
              <li>CTT</li>
              <li>The End Times</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        2.8.4
        <ul>
          <li>
            [BUG] (Units Grid) Broken tooltips (Charge Bonus, Bonus vs Large,
            Bonus vs Infantr): FIXED
          </li>
          <li>
            [BUG] (Units Cards) Lords are still showing up in weird places in
            the units finder: FIXED (Many thanks to @CChen in our Discord for
            diligently reporting issues as he encountered them!)
          </li>
        </ul>
      </li>
      <li>
        2.8.3
        <ul>
          <li>
            [BUG] (Ability Finder) Deliverance of Itza icon not loading: FIXED
          </li>
          <li>
            [CHANGE] (site settings) Default unit size is now ULTRA (previously
            LARGE)
          </li>
          <li>
            [BUG] (Unit Finder) Some Heroes are displayed under the Lords
            category: Fixed (I think... :|)
          </li>
          <li>
            [NEW] (Ability Finder) Added a small "Ability Type" icon to the
            abilities in the finder for convenience
          </li>
          <li>
            [BUG] (Ability Cards) Ability content only takes half the card on
            mobile: Content will now take all the available width.
          </li>
          <li>
            [BUG] (Ability Tooltips/Cards) Projectile Abilities display duration
            "Constant": Duration is now hidden for projectile.
          </li>
          <li>
            [BUG] (Ability Tooltips/Cards) "Map-wide" effect range show up as
            "-1": Will now correctly be displayed as "Map-wide"
          </li>
          <li>
            [NEW] (Ability Cards) Moved the "Close" button above the card into
            some kind of "Header" and added the ability icon in there for good
            measure.
          </li>
          <li>
            [NEW] (Unit Cards) Added "Mass" on Unit Cards (at the very bottom,
            just above "Fatigue Modifier")
          </li>
          <li>
            [NEW] (Unit Cards/Grid) Added "Siege Attacker" to both the Unit Grid
            (expand the Melee Attack group, column is just before "Melee Attack
            Interval") and Unit Cards (It will be the first icon at the bottom
            of the card if the unit has it)
          </li>
          <li>
            Updated mods data to the latest available as of 2021.01.30
            <ul>
              <li>SFO</li>
              <li>Radious</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        2.8.2
        <ul>
          <li>Minor bugfixes</li>
        </ul>
      </li>
      <li>
        2.8.1
        <ul>
          <li>Minor bugfixes</li>
        </ul>
      </li>
      <li>
        [BETA] I've added a new "Abilities Cards" page! As a starting point, I'm
        trying to be as close as possible to in-game tooltips. Let me know if
        you stumble upon any issue!
      </li>
      <li>
        [BETA] The tooltips of abilities/spells in the Units Cards now use the
        new Ability Tooltips (same as the ones in the Abilities Cards page)
      </li>
      <li>
        Updated the mods data to the latest available as of 2021.01.25
        <ul>
          <li>SFO</li>
          <li>Radious</li>
          <li>CTT</li>
          <li>The End Times</li>
        </ul>
      </li>
      <li>
        Updated the Radious mod key to force loading of updated images (e.g.
        unit cards)
      </li>
      <li>
        Added a new tooltip to 'Melee Attack Interval' in 'Units Grid' and
        'Units Cards' pages to clarify how the stat is used by CA.
      </li>
      <li>
        [BUG] Number of ticks in Direct Damage/Healing spells was off. I think
        the new calculation method is good but let me know if your tests don't
        match up. (Thanks to @Asddsa76 on our Discord for letting me know!)
      </li>
    </ul>
    <h2>2.7.0</h2>
    <ul>
      <li>
        2.7.2
        <ul>
          <li>[BUG] The End Times Units Grid not loading: Fixed</li>
          <li>
            Did some code refactoring around the abilities grid. Let me know if
            anything fishy happens there :P
          </li>
        </ul>
      </li>
      <li>
        2.7.1
        <ul>
          <li>[BUG] Ranged wepon data not showing up: Fixed</li>
          <li>
            Updated the mods data to the latest available as of 2021.01.11
            <ul>
              <li>SFO</li>
              <li>Radious</li>
              <li>CTT</li>
              <li>The End Times</li>
            </ul>
          </li>
          <li>
            Updated the Radious mod key to force loading of updated images (e.g.
            unit cards)
          </li>
        </ul>
      </li>
      <li>"The Asrai Resurgent Update" added"</li>
    </ul>
    <h2>2.6.0</h2>
    <ul>
      <li>
        2.6.1
        <ul>
          <li>
            [BUG] Some of the new Lords were wrongly displayed in the Hero
            section in the Unit Finder of the Unit Cards: Fixed{" "}
          </li>
          <li>
            [BUG] Mod data was incorrect: Mod data has been updated as of
            2021-01-06{" "}
          </li>
          <li>
            Core game versions and Mods are now grouped in the Units Card page
            to avoid confusion
          </li>
          <li>
            The "Main" faction is now listed first in the Unit Finder of the
            Units Cards page
          </li>
        </ul>
      </li>
      <li>"The Twisted & Twilight Update" added"</li>
      <li>
        Updated the mods data to the latest available as of 2020.12.03
        <ul>
          <li>SFO</li>
          <li>Radious</li>
          <li>CTT</li>
          <li>The End Times</li>
        </ul>
      </li>
    </ul>
    <h2>2.5.0</h2>
    <ul>
      <li>
        "Da Nutz N Boltz Update" added in place of "Da Nutz N Boltz Update
        (BETA)"
      </li>
      <li>
        Updated the mods data to the latest available as of 2020.07.26
        <ul>
          <li>SFO</li>
          <li>Radious</li>
          <li>CTT</li>
          <li>The End Times</li>
        </ul>
      </li>
    </ul>
    <h2>2.4.0</h2>
    <ul>
      <li>"Da Nutz N Boltz Update (BETA)" added</li>
      <li>
        Updated the mods data to the latest available as of 2020.06.20
        <ul>
          <li>SFO</li>
          <li>Radious</li>
          <li>CTT</li>
        </ul>
      </li>
      <li>Added "The End Times" mod</li>
      <li>
        Removed the following columns from the Unit Grid until I can figure out
        why they sometimes show up with weird variations across all units
        between some versions (I believe it might be pack files data Vs
        Assemmbly Kit XML data)
        <ul>
          <li>Radius</li>
          <li>Charge Distance Commence Run</li>
          <li>Charge Distance Adopt Charge Pos</li>
          <li>Charge Distance Pick Target</li>
        </ul>
      </li>
    </ul>
    <h2>2.3.0</h2>
    <ul>
      <li>
        2.3.1
        <ul>
          <li>
            [BUG] JSON data is missing a lot of stats: Fixed (More complex stats
            (computed from multiple other stats like missile damage, affected by
            fatigue like armour, etc.) are "properties" with a getter function
            and these were nore being included in the JSON. This has been
            corrected)
          </li>
        </ul>
      </li>
      <li>"Skull for the Skull Throne 4" version added!</li>
      <li>Updated Radious to 2020.05.29</li>
      <li>Updated CTT to 2020.05.29</li>
      <li>
        Added a setting to return Grids data as a JSON string instead of a grid
        for those interested. Accessible from the Settings menu (cogwheel icon
        top-left)
      </li>
      <li>
        Direct Damage (Phase effects) is now correclty modified according to the
        Unit Size selected in the settings (previously was using the "Large"
        modifier of .92)
      </li>
      <li>
        When comparing versions, the "Status" column now has a Tooltip that
        lists all the differences for the given row. It's much easier than
        opening up all the groups trying to find the changes and it will also
        list some more obscure properties that are compared but might not have
        their own column availble (e.g. a Phase Effect name)
      </li>
      <li>
        Temporarily reducing the number of TWW2 versions to the 2 latest only
        while I make some backend tweaks.
      </li>
      <li>
        Removed the "Item Spells" column fron Units Grids until I figure out how
        to properly display items. The current implementation was not working
        properly and led to some characters listing all the spells in that
        column.
      </li>
      <li>
        Reduced image quality overall to reduce bandwith usage. Given the
        relatively small images used on the site this should be barely
        noticeable.
      </li>
      <li>
        [BUG] Incorrect HP on some units: Fixed (There was issues with a lot of
        the units with an "engine" and also some cavalry. I ended up reworking
        my algorithm from the ground up with more empirical data)
      </li>
      <li>
        [BUG] Incorrect unit size on some units: Fixed (Was affecting War Beasts
        and some Cavalry.)
      </li>
    </ul>
    <h2>2.2.0</h2>
    <ul>
      <li>
        2.2.1
        <ul>
          <li>
            [BUG] Broken Axe faction icon is bigger than other faction icons
            (pun intended CA? :P): Fixed
          </li>
        </ul>
      </li>
      <li>"The Warden & The Paunch" version added!</li>
      <li>Updated CTT to 2020.05.13</li>
      <li>
        Updated the version order to have [Latest Version][Compatible
        Mods][Older Versions][Compatible Mods][...]
      </li>
      <li>"The Hunter & The Beast v3" version removed</li>
      <li>
        [BUG] Abilites list is incomplete: Fixed (this was something introduced
        in 2.1.8 by the "under the hood improvements")
      </li>
      <li>[BUG] Unit's Height uses the value of the unit's Radius: Fixed</li>
    </ul>
    <h2>2.1.0</h2>
    <ul>
      <li>
        2.1.8
        <ul>
          <li>
            Added "Show Secondary Missile Weapon" setting for those interested.
          </li>
          <li>More under the hood improvements and changes</li>
        </ul>
      </li>
      <li>
        2.1.7
        <ul>
          <li>
            Added proper icons and colors for Special Categories other than
            Regiments of Renown (Elector Counts, Blessed Spawnings, Tech Lab and
            Crafted) both in Unit Cards and Units Grid (Thanks to @Mixu from the
            "C&C Modding Den" Discord for the help on this!)
          </li>
          <li>
            Replaced the "RoR" column with the "Special Category" column in the
            Units Grid. You can use the following queries in the "Units Grid"
            page to search for the various Categories
            <ul>
              <li>sc = renown</li>
              <li>sc = elector</li>
              <li>sc = blessed</li>
              <li>sc = crafted</li>
              <li>sc = tech</li>
            </ul>
          </li>
          <li>
            [BUG] Adding chevrons to units sometimes result in an MP Cost with
            lots of decimals: Fixed (rounding up the number now)
          </li>
          <li>
            [BUG] Some Ammunitions showed up with lots of decimals: Fixed
            (rounding up the number now)
          </li>
        </ul>
      </li>
      <li>
        2.1.6
        <ul>
          <li>
            [BUG] Unit Grid batch loading was broken (this lead to some units
            being loaded twice while other units where not being loaded
            properly): Fixed
          </li>
          <li>
            [BUG] Melee Contact Effecs not displayed properly on Unit Cards:
            Fixed
          </li>
          <li>
            [BUG] Some stats don't show their delta properly on Unit Cards (e.g.
            Missile Base damage): Fixed
          </li>
          <li>[BUG] Fatigue tooltip broken: Fixed</li>
          <li>
            [BUG] Unit finder "version filter" was not working with CTT: Fixed
          </li>
          <li>
            When searching for units accross the entire version in the Unit
            Cards page, the search will now match against the unit name and
            mount name instead of the unit key. The unit key led to some
            confusing results sometimes and the name/mount name are more
            convenient for users.
          </li>
        </ul>
      </li>
      <li>
        2.1.5
        <ul>
          <li>
            [BUG] Ground Type Effect tooltip missing stats modifiers: Fixed
          </li>
          <li>
            [BUG] Phase's "Max Damaged Entities" not filled properly in
            Abilities Grid: Fixed
          </li>
          <li>
            [BUG] Magical, Fire, and Phase status not returned properly for
            missile explosions: Fixed
          </li>
          <li>
            Merged the find units dialogs of the "Unit Cards" page into a single
            dialog. It's now possible to search for units by key accross a
            version and to filter down a faction's units by name.
          </li>
          <li>
            Added "Is High Threat" to unit grid and unit cards with explanation
            tooltip.
          </li>
          <li>
            Added rounding to a couple of float values inside the "Phase Effect"
            so they display nicer
          </li>
          <li>
            Removed Adds when in Portrait mode and for smaller screen size. That
            should make using the site on mobile more enjoyable.
          </li>
        </ul>
      </li>
      <li>
        2.1.4
        <ul>
          <li>
            [BUG] Ammo value incorrect for units that fire multiple shots per
            volley: Fixed (e.g. Tiranoc Chariot, Hellblaster Volley Gun, etc.
            Thanks to @Norse, Devilish Picaroon from the C&C Modding Den for
            helping with that!)
          </li>
          <li>
            [BUG] Promethean Gunnery Mob (and probably others) are missing
            ranged weapon stats: Fixed (this is units that get their ranged
            attacks from the "officers" that they carry an not from their main
            entity)
          </li>
          <li>
            [BUG] Ancient Stegadon Missile Damage off by 1: Fixed (Changed from
            using Ceiling to using Rounding so 611.11 now becomes 611 instead of
            612. Hopefully this is something that changed and/or something I
            simply had wrong in the first place and this change will not break
            other units but let me know if you stumble upon stats that are off!)
          </li>
          <li>
            Moved ranged damage effect beside Missile Damage instead of
            Ammunitions on unit cards to match in-game cards
          </li>
          <li>
            Increased height of version cards to fit the added version number
          </li>
        </ul>
      </li>
      <li>
        2.1.3
        <ul>
          <li>[BUG] Incomplete units make the units grid page crash: Fixed</li>
          <li>Added Splash Attack Target Size stat to unit cards</li>
          <li>Added Splash Attack Max Attacks stat to unit cards</li>
        </ul>
      </li>
      <li>
        2.1.2
        <ul>
          <li>
            Now I'm likely stepping into a rabbit hole here but adding only SFO
            didn't feel right so I went to the TWW2 Workshop page, filtered the
            "Most Subscribed" "Overhaul" mods and picked the 3 that where "full
            game" overhauls (i.e. all the factions) on the first page. SFO was
            already there but Radious and CTT will now be on the site as well
            <ul>
              <li>Added "Radious Total War Mod (2020.02.26)" version</li>
              <li>Added "Boyz Will Be Boyz - CTT (2020.04.27)" version</li>
            </ul>
          </li>
          <li>
            Added the version number to the version names (thanks to @Finaldeath
            on Discord for the help on this)
          </li>
        </ul>
      </li>
      <li>
        2.1.1
        <ul>
          <li>
            [BUG] Comparing unit cards in SFO sometimes makes the site crash:
            Fixed (thanks @Lazarikho from SFO Discord for pointing this out!)
          </li>
        </ul>
      </li>
      <li>
        [BETA] Steel Faith Overhaul Added! Adding mods is something I've been
        wanting to try for a while and I've had a few requests for it and recent
        changes to how I extract the data made it possible. I believe the data
        should be correct but I really didn't have the time to validate this so
        please report any inconsistent/missing/wrong data :)
      </li>
      <li>Added game version's name under the game logo on unit cards</li>
      <li>
        I've made a small modification to mass computation which should now be
        closer to reality. I'm still not convinced I've nailed it 100% (added a
        tooltip on the column header to that effect) but we're getting closer
        every time :P
      </li>
      <li>
        [BUG] Radius in Kings Shilling is wrong: Fixed (big thanks to rdpmatt
        for finding and helping to solve this one!)
      </li>
      <li>
        [BUG] Unmodified units incorrectly show up as modified when comparing
        versions: Fixed (This was caused by some inconsistencies in how data is
        represented in xml vs pack files. These inconsistencies are now
        accounted for in the comparison code)
      </li>
      <li>
        [BUG] Nested stats where not being compared properly (e.g. Missile
        Weapon Explosion Damage): Fixed
      </li>
      <li>[BUG] Filtering on units grid broken: Fixed </li>
      <li>
        [BUG] AP damage missing from Fatigue Effect (in both table and unit
        cards): Fixed
      </li>
      <li>[BUG] Parenthesis break search in Units Grid: Fixed</li>
      <li>
        [BUG] Floating Point value filters (e.g. AP Ratio) do not work: Fixed
      </li>
    </ul>
    <h2>2.0.0</h2>
    <ul>
      <li>
        2.0.3
        <ul>
          <li>[BUG] Mounted units name is incorrect: Fixed</li>
          <li>[BUG] Ground Type Stat Effect name shows up as null: Fixed</li>
          <li>
            Added "Explosion Detonation Radius" to Missile Weapon columns in
            Units Grid
          </li>
          <li>
            Added "Projectile Penetration Entity Size Cap" to the "Additional
            Missile Damage Stats" group of columns in the Units Grid page
          </li>
          <li>
            Added "Projectile Penetration Max Penetration" to the "Additional
            Missile Damage Stats" group of columns in the Units Grid page
          </li>
        </ul>
      </li>
      <li>
        2.0.2
        <ul>
          <li>Fixed filtering in unit cards selection dialog</li>
          <li>
            Added Patreon button linking to twwstats Patreon page in the top
            right. I don't have any special benefits to offer at this time
            really but small contributions (1$/month really) from a few people
            would go a long way in reducing the hosting burden for me and would
            be greatly appreciated!
          </li>
        </ul>
      </li>
      <li>
        The site has received a major overhaul under the hood! It now runs on 2
        services (down from 3) and I'm pre-processing the data to reduce the
        amount of space needed to host the site. All in all this won't make much
        of a change for you but it means I can host the site on a much smaller
        machine and hopefully won't have to piggy-back on free trials for
        hosting from now on :)
      </li>
      <li>
        Added the latest version: The Kings Shilling
        <ul>
          <li>
            For some reasons I still don't understand, the Assembly Kit has
            never been updated for The Kings Shilling so I had to revert to
            reading the data directly from the data*.pack and local*.pack files.
            On the bright side this means I'm no longer reliant on CA to release
            the Assembly Kit for newer versions! The only downside I can think
            of is that I still couldn't figure out how to properly find all the
            descripton text of some tables (most notably all the _kv tables) so
            some sections of the site are a little bit less informative for
            those versions using the data directly from the pack files but for
            the most part it should be transparent to users of the site.
          </li>
        </ul>
      </li>
      <li>
        Removed TWW1 versions (at least for now). I might try to bring them back
        down the road but they were causing too much trouble with the new data
        extraction approach I use and decided to focus on other more important
        aspects of the site.
      </li>
      <li>
        Created a Patreon for twwstats to help with the hosting cost and every
        contribution is greatly appreciated! You can visit it at
        https://www.patreon.com/twwstats
      </li>
    </ul>
    <h2>1.18.0</h2>
    <ul>
      <li>
        TEMPORARILY REVERTED THE KINGS SHILLING VERSION UNTIL ASSEMBLY KIT GETS
        PROPERLY UPDATED
      </li>
      <li>Added the latest version: The Kings Shilling</li>
      <li>
        Removed older versions
        <ul>
          <li>The Amethyst Update</li>
          <li>The Hunter & The Beast V1</li>
          <li>The Hunter & The Beast V2</li>
          <li>The Shadow & The Blade V1</li>
          <li>The Shadow & The Blade V2</li>
        </ul>
      </li>
    </ul>
    <h2>1.17.0</h2>
    <ul>
      <li>
        1.17.4
        <ul>
          <li>
            Black Orcs have FINALLY been filtered out of the Vampire Coast unit
            cards roster. To my defense there IS a black orcs unit related to
            the Vampire Coast faction in the DB but they are marked as a "naval"
            unit (yeah like that makes more sense :P). On the bright side the
            fix will take care of any other cases that might exist or come in
            the future!
          </li>
        </ul>
      </li>
      <li>
        1.17.3
        <ul>
          <li>
            Fixed the phase effect tooltip for healing abilities that only
            affects 1 entity/unit to correctly mention it (previously they
            incorrectly stated all entity in the unit were affected). (Thanks to
            @WojmirVonCarstein for pointing and testing this out!)
          </li>
        </ul>
      </li>
      <li>
        1.17.2
        <ul>
          <li>
            Added back the "View in Ability Grid" button to the following
            columns of the Unit Grid (thanks to @Griffithxi from the TWW Forums
            for the reminder :))
            <ul>
              <li>Abilities</li>
              <li>Spells</li>
              <li>Item Abilities</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        1.17.1
        <ul>
          <li>
            Fixed version delta that was making all abilities to show up as
            "modified" when comparing version even if they had no differences
          </li>
        </ul>
      </li>
      <li>
        "TWW2 - The Shadow & The Blade v3" version has been ADDED to the list!
      </li>
    </ul>
    <h2>1.16.0</h2>
    <ul>
      <li>
        1.16.4
        <ul>
          <li>Fixing images size</li>
        </ul>
      </li>
      <li>
        1.16.3
        <ul>
          <li>Fixing missing images</li>
        </ul>
      </li>
      <li>
        1.16.2
        <ul>
          <li>Resolving technical problem</li>
        </ul>
      </li>
      <li>
        1.16.1
        <ul>
          <li>
            "TWW2 - The Shadow & The Blade v2" version has been ADDED to the
            list!
          </li>
          <li>
            "TWW2 - The Hunter and the Beast v2" version has been REMOVED to the
            list!
          </li>
          <li>
            "TWW2 - The Hunter and the Beast v1" version has been REMOVED to the
            list!
          </li>
        </ul>
      </li>
      <li>
        "TWW2 - The Shadow & The Blade" version has been added to the list!
      </li>
    </ul>
    <h2>1.15.0</h2>
    <ul>
      <li>
        1.15.1
        <ul>
          <li>Fixed banner size on HD and lower screen size</li>
        </ul>
      </li>
      <li>
        "TWW2 - The Hunter and the Beast v3" version has been added to the list!
        (for a lack of a better name :P)
      </li>
    </ul>
    <h2>1.14.0</h2>
    <ul>
      <li>
        1.14.3
        <ul>
          <li>
            Tried to improve the sizing of ads on mobile devices which has
            gotten out of hands and makes the site barely usable in some
            situations
          </li>
        </ul>
      </li>
      <li>
        "TWW2 - The Hunter and the Beast v2" version has been added to the list!
        (for a lack of a better name :P)
      </li>
      <li>
        Introducing the "Warlord Traits Grid" page! It's a bit rough around the
        edges but it's a start ;)
      </li>
    </ul>
    <h2>1.13.0</h2>
    <ul>
      <li>
        1.13.12
        <ul>
          <li>
            [BUG] Fixed a major bug when comparing versions introduced by recent
            refactoring of the querying code that led to only half of each
            verision being loaded and compared. (Many thanks to @Kaffeemann in
            Discord for reporting the problem!)
          </li>
        </ul>
      </li>
      <li>
        1.13.11
        <ul>
          <li>Added "Add Unit(s) by Name" dialog to the Units Cards page.</li>
        </ul>
      </li>
      <li>
        1.13.10
        <ul>
          <li>
            [BUG] Expanding the Damage column in the Unit Grid will now work
            properly (it used to break the table)
          </li>
          <li>
            For those wondering, 1.13.8 and 1.13.9 were too small to justify a
            changelog :P
          </li>
        </ul>
      </li>
      <li>
        1.13.7
        <ul>
          <li>
            Modified querying of units in unit grid to hopefully allow caching
            even for the latest version
          </li>
          <li>
            Grouped Unit Cards factions by subculture as I felt it was getting a
            bit hard to find what you're looking for. Let me know if it makes
            things too big!
          </li>
          <li>
            More library updates (I made the recommended sacrifices to the
            programming code so we should be all good)
          </li>
          <li>
            For those wondering, 1.13.1, 1.13.2, 1.13.3, 1.13.4, 1.13.5, 1.13.6
            where just a lot of head banging on my desk
          </li>
        </ul>
      </li>
      <li>
        "TWW2 - The Hunter and the Beast" version has been added to the list!
      </li>
      <li>"TWW2 - The Festag Update" version removed</li>
      <li>
        Lots of code packages updated so there might be some unwanted
        side-effects... please let me know if you stumble upon something that
        broke.
      </li>
      <li>
        Unit Grid
        <ul>
          <li>Ap ratio for missile weapons added to the unit grid</li>
        </ul>
      </li>
      <li>
        Unit Cards
        <ul>
          <li>
            [BUG] Fixed delta of stats that was not working correctly in certain
            corner cases (i.e. the Bonus vs Infantry when comparing Witch Elves
            and Sisters of Slaughter) (thanks @Bogdanov89 from discord for
            reporting the issue)
          </li>
        </ul>
      </li>
      <li>
        Abilities Grid
        <ul>
          <li>
            [BUG] Max Damaged Entities will now be loaded correctly instead of
            displaying 'undefined' everywhere
          </li>
        </ul>
      </li>
    </ul>
    <h2>1.12.0</h2>
    <ul>
      <li>
        1.12.1
        <ul>
          <li>
            [BUG] Fixed HP computation algorithm (at least I think :P) (thanks
            @TigerusTheReal from reddit and @GloriousFeeder from Discord for
            reporting the issue)
          </li>
        </ul>
      </li>
      <li>"TWW2 - The Aye Aye Patch" version has been added to the list!</li>
      <li>"TWW2 - The Amethyst Update" version has been added to the list!</li>
      <li>
        In order to reduce the size of the project, only the Vanilla version and
        the version of the last 3 updates will be maintained for TWW2 (TWW1
        Vanilla and Latest versions will remain untouched)
        <ul>
          <li>"TWW2 - The Aye Aye Patch" version removed</li>
          <li>"TWW2 - The Kharibdyss Update" version removed</li>
          <li>"TWW2 - The Resurgent Update" version removed</li>
          <li>"TWW2 - MWNL 2018" version removed</li>
        </ul>
      </li>
    </ul>
    <h2>1.11.0</h2>
    <ul>
      <li>
        "TWW2 - The Prophet & The Warlock" version has been added to the list!
      </li>
    </ul>
    <h2>1.10.0</h2>
    <ul>
      <li>"TWW2 - The Festag Update" version has been added to the list!</li>
    </ul>
    <h2>1.9.0</h2>
    <ul>
      <li>
        1.9.1
        <ul>
          <li>
            [BUG] Fixed error in units-grid introduced with the Aye Aye patch
            data
          </li>
        </ul>
      </li>
      <li>"TWW2 - The Aye Aye Patch" version has been added to the list!</li>
    </ul>
    <h2>1.8.0</h2>
    <ul>
      <li>
        1.8.2
        <ul>
          <li>
            [BUG] Ability "Multiplayer (Cost)" now display the correct value
          </li>
        </ul>
      </li>
      <li>
        1.8.1
        <ul>
          <li>
            [UPDATED] Units Cards
            <ul>
              <li>
                [NEW] Attributes, Abilities, Spells and Items Abilities. It's
                just the icons, name and description for now (i.e. haven't had
                time to include details such has cost, effects, etc. to the
                tooltips)
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        "TWW2 - The Kharibdyss Update" version has been added to the list!
      </li>
      <li>
        [UPDATED] Units Cards
        <ul>
          <li>[NEW] "Bonus vs Infantry" (for Missile Weapons)</li>
          <li>[NEW] "Bonus vs Large" (for Missile Weapons)</li>
        </ul>
      </li>
    </ul>
    <h2>1.7.0</h2>
    <ul>
      <li>
        1.7.4
        <ul>
          <li>
            [IMPROVEMENT] The Unit Name will now stick to the top when scrolling
            down past it in the Units Cards page so you don't have to scroll
            back up to see which unit a card belong to when you have many cards
            displayed.
          </li>
          <li>
            [UPDATED] You must now "click" stats with a tooltip to view the
            tooltip in the Units Cards page. The cursor will change to a "help"
            cursor when you mouse over a stat that has a tooltip.
          </li>
        </ul>
      </li>
      <li>
        1.7.3
        <ul>
          <li>
            [BUG] Missile Damage (over 10s) now correctly take projectile_number
            into account. The{" "}
            <a href="https://twwstats.com/units?q=projectile%20number%20%3E%201">
              following units
            </a>{" "}
            are affected. (thanks @Kaffeemann for finding this!)
          </li>
          <li>
            [BUG] A 35 Parry Chance will now correctly use the Bronze Shield in
            the Units Cards page. (thanks @Kaffeemann for finding this!)
          </li>
          <li>
            [BUG] Comparison of Parry Chance will now work properly for units
            with Parry Chance = 0
          </li>
          <li>
            [UPDATED] Units Grid
            <ul>
              <li>[NEW] "Projectile Number"</li>
            </ul>
          </li>
          <li>
            [UPDATED] Units Cards
            <ul>
              <li>
                [UPDATED] Stats that had only their name as a Tooltip will no
                loner have a tooltip in the "Units Cards" page. Vertical
                scrolling is not working well when a tooltip is being displayed
                so I'll try to keep them to a minimum. Stats that do have a
                tooltip available will use the "help" cursor when you mouse over
                them.
              </li>
              <li>
                [UPDATED] "Fire Resistance/Weakness" renamed to "Fire
                Resistance" to avoid using 2 lines on mobile devices. It is
                implied that a negative resistance value is a "weakness".
              </li>
              <li>
                [UPDATED] "Shield (Parry Chance)" renamed to "Parry Chance" to
                avoid using 2 lines on mobile devices.
              </li>
              <li>[NEW] "Health per Entity"</li>
              <li>[NEW] "Attack Interval" (Melee Attack Interval)</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        1.7.2
        <ul>
          <li>
            [UPDATED] Units Grid
            <ul>
              <li>[NEW] "AP/Total Dmg Ratio"</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        1.7.1
        <ul>
          <li>
            [BUG] The Fatigue Effect for "Melee Defence" will now be loaded and
            applied properly (thanks @Kaffeemann for finding this!)
          </li>
          <li>
            "Melee Attack Interval" is now rounded to the first decimal to make
            the value fit in the column (thanks @Kaffeemann for finding this!)
          </li>
        </ul>
      </li>
      <li>
        Unit Size Setting: Click the cogwheel in the top-right to access the
        "Settings" and change the unit_size used on the site.
        <ul>
          <li>Default is "Large"</li>
          <li>
            Unit Size affects the number of entities per unit, the health of
            units and the damage of Direct Damage spells
          </li>
        </ul>
      </li>
      <li>
        Units Cards
        <ul>
          <li>
            [NEW] Fatigue Effect: Select a different fatigue level (between the
            Unit Size and Rank) to view what effect it has on a unit's stats
          </li>
          <li>[NEW] Attributes (previously in a TODO state)</li>
          <li>[NEW] SP Cost</li>
          <li>[NEW] SP Upkeek</li>
          <li>[NEW] MP Cost</li>
          <li>[NEW] Ward Save</li>
          <li>[NEW] Physical Resistance</li>
          <li>[NEW] Missile Resistance</li>
          <li>[NEW] Magic Resistance</li>
          <li>[NEW] Fire Resistance/Weakness</li>
          <li>
            [NEW] Total Accuracy: Sum of "Accuracy" + "Marksmanship Bonus".
          </li>
          <li>[NEW] Calibration Distance</li>
          <li>[NEW] Calibration Area</li>
          <li>
            [NEW] Fatigue Modifier: This modifier is associated to a Unit's
            Rank. It reduces the speed at which a unit gets tired and make a
            unit recover faster.
          </li>
        </ul>
      </li>
      <li>
        Bottom Ads: My goal is to keep twwstats around for as long as the Total
        War Warhammer series lives. The Ads are there to help cover the hosting
        cost associated with the site.
      </li>
      <li>[BUG] Ability Types will now display as an icon properly</li>
    </ul>
    <h2>1.6.0</h2>
    <ul>
      <li>
        1.6.3
        <ul>
          <li>
            Mass formula updated. This is pretty hard to validate but according
            to my tests and the info I could gather the "mass" of a unit is the
            mass of the "moving"/"main" battle entity. That means ONLY the mass
            of the engine for chariots, ONLY the mass of the mount for cavs,
            etc.
          </li>
          <li>
            [BUG] Fixed an issue that prevented the unit finder from being
            scrolled by interacting with the scroll bar directly
          </li>
          <li>
            [BUG] Campaign Difficulties versin compare now works properly (was
            always returning "the two versions are the same" previously)
          </li>
          <li>
            [Units Cards] "Compare to First" is now the default comparison mode
            (previously "None" was)
          </li>
          <li>Normalized line heights in Units Cards for cleaner look</li>
        </ul>
      </li>
      <li>
        1.6.2
        <ul>
          <li>
            Units Grid
            <ul>
              <li>
                [MODIFIED] Missile Damage Group
                <ul>
                  <li>
                    [NEW] Missile Bonus Vs Infantry (from the DB only the Eagle
                    Claw Bolt Thrower/Reaper Bolt Thrower "spread" mode has this
                    at 8{" "}
                  </li>
                  <li>
                    [NEW] Missile Bonus Vs Large (Bone Giant has a wooping 150
                    value for this! I assume it adds up to the damage the same
                    way the melee bonuses add up. Not sure if it has other
                    effects)
                  </li>
                </ul>
              </li>
              <li>
                [MODIFIED] Additional Missile Damage Group
                <ul>
                  <li>[NEW] Total Accuracy (Accuracy + Marksmanship Bonus)</li>
                  <li>[NEW] Calibration Distance</li>
                  <li>[NEW] Calibration Area</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Modified the hosting architecture of the site to reduce cost while
            improving performances a bit :)
          </li>
        </ul>
      </li>
      <li>
        1.6.1
        <ul>
          <li>
            You can now select a Mount for Lords and Heroes in the Unit Cards
            page when applicable. The mount selection is done by clicking the
            Mount icon left of the "Category" just below the unit's name.
          </li>
        </ul>
      </li>
      <li>
        "TWW2 - The Resurgent Update" version has been added to the list. The
        versions being already listed from "newest" to "oldest", using the
        actual patch name will make more sense in the long term.
      </li>
      <li>
        The previous "TWW2 - Latest" version has been renamed "TWW2 - MWNL 2018"
        instead (i.e. Make War Not Love 2018)
      </li>
      <li>
        New "Units Cards" page! (It's kind of an "alpha" feature at this point
        but I think there is enough functionatlies already to be
        useful/interesting)
        <ul>
          <li>
            Choose and compare units accross games and factions using a "unit
            card" visual inspired by the in-game unit cards
          </li>
          <li>
            Choose between three comparison mode
            <ul>
              <li>
                No Compare: Simply display the cards without any comparison
                information added.
              </li>
              <li>
                Compare to First: The first card becomes the reference and all
                other cards are compared to it.
              </li>
              <li>
                Compare to Previous: Each card is compared with the card
                directly to its left.
              </li>
            </ul>
          </li>
          <li>
            Change the "Rank" of units to see live how it affects their stats
          </li>
          <li>
            Use the "Card Controls" buttons to move cards around, remove cards
            and clone cards (useful if you want to quickly compare the same unit
            at different ranks)
          </li>
        </ul>
      </li>
      <li>
        New basic Home Page for better visibility on available pages and faster
        initial load time
      </li>
      <li>[BUG] Clearing the Filter Query will no longer crash the site</li>
    </ul>
    <h2>1.5.0</h2>
    <ul>
      <li>
        1.5.1
        <ul>
          <li>
            Abilities Grid: Fixed Spawned Units Image which should fix crashes
            of the Abilities Grid as well
          </li>
          <li>
            Filter Queries: "!=" queries with arrays will now work properly and
            exclude rows mathching any of the provided values (e.g. size !=
            (small, large) will return non-small, non-large units). Also fixed
            an issue if extra space was added at the end of the query
          </li>
        </ul>
      </li>
      <li>
        New Grids!
        <ul>
          <li>
            Fatigue Effects (what stats are affected by Fatigue, aka Vigor)
          </li>
          <li>
            Campaign Difficulties (all effects Campaign Difficulties have on the
            Player and the AI)
          </li>
          <li>
            _kv_morale (Values related to how a Unit gain/lose Leadership during
            a battle)
          </li>
          <li>
            _kv_fatigue (Values related to how a Unit gain/lose Vigor during a
            battle)
          </li>
          <li>_kv_key_buildings (Values related to Building like Towers)</li>
          <li>
            _kv_unit_ability_scaling_rules (Values related Abilities, notably
            how Direct Damage scales accross Unit Sizes)
          </li>
          <li>
            _kv_winds_of_magic_params (Values related to the Winds of Magic, how
            much you can have, how fast it recharges, etc.)
          </li>
        </ul>
      </li>
      <li>
        _kv_rules updates
        <ul>
          <li>
            Fixed version compare which will now correctly show ONLY rows with
            differences between the selected versions.
          </li>
          <li>New "Description" Column</li>
          <li>
            PRO TIP: This table also contains the Battle Difficulty effect on AI
            Units stats. Use the <em>key = difficulty_mod</em> Filter Query
          </li>
        </ul>
      </li>
      <li>
        Units Grid updates
        <ul>
          <li>
            Added a new "Ground Type Effect Group" column inside the "Is Large"
            Columns Group with a tooltip listing the effect(s) of various ground
            types on the unit
          </li>
          <li>Added the "Spells" column that works similarly to "Abilities"</li>
          <li>
            Added the "Item Abilities" column that works similarly to
            "Abilities". This is more geared towards MP but I`ll try to add the
            SP effects of items as well at some point.
          </li>
          <li>
            Moved the "Attributes", "Abilities", "Spells" and "Item Abilities"
            columns into their respective "one column group". This is to speed
            up loading of the table for people who don't care about these
            columns. As always group state (closed/openned) is saved in your
            browser so if you always want to see these columns just open them
            all once and you'll be good to go!
          </li>
          <li>
            Added some rounding to the "Health/Model" because some values had a
            lot of decimal (thanks @Kaffeemann for finding this!). This is
            likely due to me using "unit size: large" values.
          </li>
          <li>
            A Parry Chance of 35% will now correctly use the "Bronze Shield"
            instead of the "Silver Shield" (thanks @Kaffeemann for finding
            this!)
          </li>
        </ul>
      </li>
      <li>
        Filter Queries improvements
        <ul>
          <li>
            The == operator now performs exact matches (still case invariant
            though). "NAME == GOBLINS" will return only the "Goblins" unit while
            "NAME = GOBLINS" will return any unit with "Goblins" in their name.
          </li>
          <li>
            You can now provide a list of argument to a query operation. For
            example:"NAME=(FRANZ,MANNFRED)" will return any unit which name
            matches EITHER "FRANZ" or "MANNFRED"
          </li>
        </ul>
      </li>
      <li>
        All images are now "optimized" and served from a Content Delivery
        Network (CDN) for improved performances and reduced bandwith usage
      </li>
    </ul>
    <h2>1.4.0</h2>
    <ul>
      <li>
        1.4.2
        <ul>
          <li>
            Added a new "View Abilities" button to the "Units Grid" "Abilities"
            column. Use it to load a Unit's Abilities into the "Abilities Grid"
            <ul>
              <li>
                You can use "Ctrl + Click" or "MiddleMouseButton Click" to load
                the "Abilities Grid" in a differen tab while keeping your
                current "Units Grid" tab intact
              </li>
            </ul>
          </li>
          <li>
            When comparing versions, you can use the special "modified" value
            against any column to view only units that got this property
            modified between the two versions.
            <ul>
              <li>
                For example: "faction = empire & melee ap = modified" will
                return all empire unit with changes to their Melee
                Armor-Piercing Damage between the two selected versions
              </li>
            </ul>
          </li>
          <li>
            New stats added
            <ul>
              <li>
                [MODIFIED] "Is Large" Group
                <ul>
                  <li>[MODIFIED] Mass -> New icon</li>
                  <li>[NEW] Weight</li>
                  <li>[NEW] Radius</li>
                  <li>[NEW] Combat Reaction Radius</li>
                  <li>[NEW] Height</li>
                </ul>
              </li>
              <li>
                [MODIFIED] "Speed" Group
                <ul>
                  <li>[MODIFIED] Fly Speed -> New icon</li>
                </ul>
              </li>
              <li>
                [MODIFIED] "Melee Attack" Group
                <ul>
                  <li>[MODIFIED] Melee Attack Interval -> New icon</li>
                  <li>
                    [MODIFIED] Splash Attack Target Size -> New icon and more
                    info in Header Tooltip
                  </li>
                  <li>
                    [MODIFIED] Splash Attack Max Attacks -> New icon and more
                    info in Header Tooltip
                  </li>
                  <li>
                    [MODIFIED] Splash Attack Power Multiplier -> More info in
                    Header Tooltip
                  </li>
                </ul>
              </li>
              <li>
                [MODIFIED] "Melee Defence" Group
                <ul>
                  <li>[NEW] Hit Reactions Ignore Chance</li>
                  <li>[NEW] Knock Interrupts Ignore Chance</li>
                </ul>
              </li>
              <li>
                [MODIFIED] "Charge Bonus" Group
                <ul>
                  <li>[NEW] Charge Speed</li>
                  <li>[NEW] Flying Charge Speed</li>
                  <li>[NEW] Charge Distance Commence Run</li>
                  <li>[NEW] Charge Distance Adopt Charge Pose</li>
                  <li>[NEW] Charge Distance Pick Target</li>
                  <li>[NEW] Collision Attack Max Targets</li>
                  <li>[NEW] Collision Attack Max Targets Cooldown</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        1.4.1
        <ul>
          <li>
            Added more informative tooltip to Phases that do Direct Damage or
            Healing. It's a bit verbose right now but it should give a more
            accurate idea of that the spell do :)
          </li>
          <li>
            Phase Direct Damage value displayed is now the "Unit Size: Large"
            value (0.92 * base value) to match the "Unit Size" used in the rest
            of the site
          </li>
          <li>
            Fixed a bug that was causing "Phases Max Damaged Entities" to always
            show as "undefined"
          </li>
          <li>
            Changed 'Number of Effected Units', 'Number of Effected Friendly
            Units' and 'Number of Effected Enemy Units' to 'Number of Affected
            Units', 'Number of Affected Friendly Units' and 'Number of Affected
            Enemy Units' (Thanks to @Solstice314 for reporting this!)
          </li>
          <li>
            Fixed typo in "Ennemy" to "Enemy" (Thanks to @Solstice314 for
            reporting this!)
          </li>
          <li>
            Dragging a column group will now move all the children columns along
          </li>
          <li>
            Changed icon for Phase HP Change Frequency as I wrongly assumed it
            was only relevant to healing Phases but it also is used for Damage
            Phases. Also reordered the Ability Phase Group columns a bit.
          </li>
        </ul>
      </li>
      <li>
        Abilities Grid!
        <ul>
          <li>
            The all new "Abilities" grid can be accessed from the top-left menu
          </li>
          <li>
            Abilities include Spells, Items, Character Abilities, Hero
            Abilities, Passive Skills, Runes, Banners and probably more :P
          </li>
          <li>
            There is a LOT of data related to abilities. I think I've done a
            decent job organising and grouping it but feedback is always
            welcome.
          </li>
          <li>
            The Abilities Grid is fully compabible with the "Versions Compare"
            feature!
          </li>
        </ul>
      </li>
      <li>
        Units Grid
        <ul>
          <li>
            Added "Is Large" column and grouped "Entity Size" under it. "Entity
            Size" is more of an "under te hood" stat for Splash Attacks and
            Projectile Penetration while "Is Large" is represented in the ui and
            used for Bonus_v_Large and Bonus_v_Infantry (aka Bonus_v_Small).
          </li>
          <li>
            Abilities now have better tooltips with the name and short
            description of the ability
          </li>
          <li>
            Columns with "keys" as their name have been updated with proper
            names
          </li>
        </ul>
      </li>
      <li>
        "Boolean" columns now display the header's icon when the value is "true"
        for faster and easier reaeding.
      </li>
      <li>
        Deltas are now available for "string" content. Modified cells will show
        up in yellow. Select the cell to view the values in the "new (old)"
        format in the bottom info bar.
      </li>
      <li>
        Started adding filter query "info" inside tooltips. If you see content
        inside brackets, it represents the "value" of the current cell when
        filtering. (e.g. Boolean columns use 1 for "true" and 0 for "false")
      </li>
      <li>
        Added a lot of missing details inside Phase's tooltips (Not too sure how
        I missed that the first time!)
      </li>
      <li>Improved Column Headers tooltips</li>
      <li>
        Improved Cell tooltips look and feel and added mechanics information to
        some of them (e.g. Melee Attack, Melee Defence, Bonus Vs Large)
      </li>
      <li>
        Fixed an issue that was preventing some browser from leveraging the
        cached data when querying the server. (Most notably users of the Edge
        browser but potentially others as well.)
      </li>
      <li>Lots of "under the hood" cleanup :)</li>
    </ul>
    <h2>1.3.0</h2>
    <ul>
      <li>
        1.3.1
        <ul>
          <li>
            Added nice tooltips for "Parry Chance" (Shield) and "Category"
            column
          </li>
          <li>
            Shield icon now displayed beside the parry chance value in the
            "Parry Chance" column
          </li>
          <li>
            Text within the grid is now selectable (i.e. you can now copy-pate
            text)
          </li>
          <li>
            Magic Attack and Flaming Attack columns now display their respective
            icons instead of 1/0 values
          </li>
          <li>
            App is now explicitly resized to fill the entire window. (I have
            hopes this could fix the Windows Phone/iPhone/iPad blank page
            issues)
          </li>
        </ul>
      </li>
      <li>
        Filter Queries are live! While the old "just type a word" filter will
        still work, you can now do more complex filter queries such as "SKAVEN &
        (MA > 40 || MD > 40) & TYPE != MONSTER". Full details can be found in
        the "Help" dialog (question mark icon at the top-right)
      </li>
      <li>
        New stats
        <ul>
          <li>
            Status (new, modified, removed). Only available when comparing
            versions
          </li>
          <li>Create Time (added right of the Upkeep column)</li>
          <li>
            Attributes (e.g. Causes Fear, Vanguard Deployment, etc.). Combined
            with the search queries, you can now search for specific attributes!
            (for example: <strong>attr = fear</strong>){" "}
          </li>
        </ul>
      </li>
      <li>
        Added the option to do versions compare from the "Units Grid" page. The
        "Compare Versions" page might be phased out eventually since both pages
        are idendical (except for the initial value of the "left version").
      </li>
      <li>
        Fixed a bug that prevented Weapon Strength and Speed to display properly
        in Compare mode
      </li>
      <li>
        Expanded/Collapsed column groups state is now stored locally (i.e. in
        the browser) and will persist accross sessions, page loads and versions
        change.
      </li>
      <li>
        Converted melee/missile/explosion flaming attacks to a boolean (see{" "}
        <a
          target="_blank"
          href="https://forums.totalwar.com/discussion/216932/why-are-flaming-attacks-a-value-instead-of-a-boolean-in-the-db#latest"
          rel="noopener noreferrer"
        >
          answers from CA_Duck
        </a>
        )
      </li>
    </ul>
    <h2>1.2.0</h2>
    <ul>
      <li>
        1.2.4
        <ul>
          <li>Added tooltips to "Magical Attacks" and "Flamin Attacks"</li>
          <li>
            Increased row heighg to be able to reduce "Name" column for better
            experience on small screens
          </li>
          <li>Increased ability icons size in prevision of future tooltips</li>
          <li>New stat: Health per model (expand the "Health" group to see)</li>
        </ul>
      </li>
      <li>
        1.2.3
        <ul>
          <li>
            Added tooltips to Contact Phases detailing attributes/stats effects
          </li>
          <li>Added the "Entity size" column (small, medium, etc.)</li>
          <li>
            Now using different icons for each "additional information" columns.
            Still not the best but a small improvements over using the same icon
            for everything.
          </li>
          <li>
            Fixed compare page bug where clearing the left version (selectiong
            '- - -') would not reload the units correclty
          </li>
          <li>
            Improved sorting of 'contact phase', 'entity size' and
            'splash_attack_target_size' columns
          </li>
        </ul>
      </li>
      <li>
        1.2.2
        <ul>
          <li>Minor bug and typos fixes</li>
        </ul>
      </li>
      <li>
        1.2.1
        <ul>
          <li>Minor bug fixes</li>
        </ul>
      </li>
      <li>
        Added a whole bunch of new and/or improved stats!
        <ul>
          <li>
            Armour
            <ul>
              <li>Physical Resistance</li>
              <li>Missile Resistance</li>
              <li>Magic Resistance</li>
              <li>Fire Resistance</li>
              <li>
                Ward Save (Resist All). No unit seems to use it yet but it's in
                the DB :)
              </li>
            </ul>
          </li>
          <li>
            The Speed column will now display the "fly speed" for flying units
            instead of their running speed to match in-game unit cards.
            Expanding the Speed column will show the two valus (run and fly).
            Thx to @Kaffeemann in Discord for pointing this out to me :)
          </li>
          <li>
            Melee Attack
            <ul>
              <li>Is Magical</li>
              <li>Ignition Amount (aka Flaming Attack)</li>
              <li>
                Contact Phase (on-hit effects like poison and sunder armor).
                Pretty basic for now but will try to add more details about the
                actual effects later on.
              </li>
            </ul>
          </li>
          <li>
            Additional Melee Attack Stats. This contains stats that are a bit
            more obscure (to me at least :P) so I split them up from the other
            Melee Attack stats for convenience.
            <ul>
              <li>melee_attack_interval</li>
              <li>weapon_length</li>
              <li>
                splash_attack_traget_size (moved from the "Weapon Strength"
                group)
              </li>
              <li>
                splash_attack_max_attacks (moved from the "Weapon Strength"
                group)
              </li>
              <li>
                splash_attack_power_multiplier (moved from the "Weapon Strength"
                group)
              </li>
              <li>collision_attack_max_targets</li>
              <li>collision_attack_max_targets_cooldown</li>
            </ul>
          </li>
          <li>
            Missile Damage
            <ul>
              <li>Magical Attack</li>
              <li>Flaming Attack</li>
              <li>
                Contact Phase (on-hit). Pretty basic for now but will try to add
                more details about the actual effects later on.
              </li>
              <li>
                Contact Phase (overhead). Pretty basic for now but will try to
                add more details about the actual effects later on.
              </li>
              <li>Explosion Magical Attack</li>
              <li>Explosion Flaming Attack</li>
              <li>
                Explosion Contact Phase (on-hit). Pretty basic for now but will
                try to add more details about the actual effects later on.
              </li>
            </ul>
          </li>
          <li>
            Additional Missile Damage Stats. This contains stats that are a bit
            more obscure (to me at least :P) so I split them up from the other
            Melee Attack stats for convenience.
            <ul>
              <li>Accuracy</li>
              <li>Marksmanship Bonus</li>
              <li>projectile_number</li>
              <li>collision_radius</li>
              <li>burst_size</li>
              <li>burst_shot_delay</li>
            </ul>
          </li>
          <li>
            "Damage" column renamed to "Weapon Strength" to match in-game cards.
            Thx to @Kaffeemann in Discord for pointing this out to me :)
          </li>
          <li>
            Abilities. <strong>This is a Work in Progress.</strong> I am aware
            the column in its current state is not very useful :( I will try to
            improve the usability (tooltips? click icon to get details?) in the
            future so just hang in there!
          </li>
        </ul>
      </li>
      <li>
        Replaced column header text with icons for the following columns. Thx to
        @sagranda2/@Sagranda in TWW Forums/Reddit for the suggestion :)
        <ul>
          <li>Category</li>
          <li>Regiments of Renown (RoR)</li>
        </ul>
      </li>
      <li>
        Selected Versions, Pinned Rows and the Filter Text are now stored in the
        URL.
      </li>
      <li>
        Added a share button that copies the URL your clipboard. With the URL
        parameters above it should make sharing units comparison much easier.
      </li>
      <li>Improved responsivness of cell selection</li>
      <li>
        In Compare mode, added/removed units will now have a green/red glow to
        the left of their "name" cel instead of painting the entire row (chich I
        felt was a bit too agressive + it hid the purple coloring of RoR)
      </li>
      <li>Added the "_kv_rules" page to the menu to make it accessible</li>
      <li>
        Ordering is now consistent between the "Units Grid" and the "Compare
        Versions" pages
      </li>
      <li>
        Improved query caching on the server side. Should help reduce occurance
        of long queries and errors.
      </li>
      <li>
        Replaced icon with current page name in the page header (between the
        menu and share/help buttons)
      </li>
    </ul>
    <h2>1.1.0</h2>
    <ul>
      <li>
        Added the new <a href="/compare">"Compare Versions"</a> feature! You can
        now see the stats differences between games and/or versions!
      </li>
      <li>Added the changelog page, menu and help dialog</li>
      <li>
        Properly configured the manifest.json file so you can now "Add to
        homescreen" on your mobile phone and you will have a nice "TWW Stast"
        icon added to your homescreen for easy access
      </li>
      <li>
        Changed order and size of columns to make "useful" data more readily
        available and improve mobile experience
      </li>
      <li>Name column is now pinned</li>
      <li>
        Added the "Mass" column. While the values seem to make sense, it's very
        hard to validate wether or not they are 100% representative of in-game
        behavior.
      </li>
      <li>
        https://twwstats.com now properly redirects to https://www.twwstas.com
      </li>
      <li>
        Pressing a header's icon now triggers sorting as it should on mobile
      </li>
      <li>
        Pressing a header group's expand/collapse icon now works on mobile
      </li>
      <li>
        Improved the initial order of units displayed to make it more "natural".
        Units will now initially be grouped by faction and ordered by "Caste"
        (i.e. Lords, Heroes, ...)
      </li>
      <li>
        You can no longer select text inside the grid. This makes dragging
        columns around easier. I plan on making "text selection" a toggle in the
        future for convenience.
      </li>
      <li>
        Made sure every cell has a proper tooltip for convenience (depending on
        the cell, this will either be the cell value or the column name)
      </li>
      <li>
        A lot of work has been done "under the hood" to make future development
        easier :)
      </li>
      <li>
        Added the "selected cell" info bar at the bottom of the grids. It
        provides the unit name, column name and column value based on the
        selected cell.
      </li>
      <li>
        Added "splash_attack_target_size", "splash_attack_max_attacks" and
        "splash_attack_power_multiplier" to the melee damage group (expand the
        gorup to view them)
      </li>
    </ul>
    <h2>1.0.0</h2>
    <ul>
      <li>
        By default the site loads with the latest TWW2 data (the Make War, Not
        Love Patch of February 14 as of this writing) but you can select other
        options in the top-left corner. I've only included a limited number of
        versions for now but I might add more in the future.
      </li>
      <li>
        Each column can be ordered by clicking on its header. Multiple columns
        ordering is also possible by holding shift and clicking on each column
        you want to order by.
      </li>
      <li>
        Column groups are expandable (the "+" icon in the top row). Expanded
        columns usually display detailed information of the data of the
        collapsed column (e.g. Damage becomes Non-AP Damage, AP-Damage, Bonus vs
        Large, Bonus vs Infantry)
      </li>
      <li>The filtering box at the top applies across the entire columns.</li>
      <li>
        Double-clicking on a row will "pin" a copy of that row at the top
        (useful if you want to compare multiple units). Double-clicking on a
        pinned row will remove the pinned copy. (Pro-tip: if you want to see
        just your pinned rows, type in some garbage in the filtering box to hide
        everything else)
      </li>
    </ul>
  </div>
);
